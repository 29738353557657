import {useEffect} from 'react';
import {useTranslation} from "react-i18next";


export default function Legal({match}) {

    const tab = match.params.tab;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <div className="container mx-auto grid grid-cols-12 gap-x-6 px-6 mb-32 mt-40">
                <div className="col-span-10 col-start-2 space-y-8 pb-64">
                    {tab === "copyright" ?
                        <Copyright/> : <></>
                    }
                    {tab === "fraud" ?
                        <FraudAlert/> : <></>
                    }
                    {tab === "privacy" ?
                        <Privacy/> : <></>
                    }
                    {tab === "terms" ?
                        <TermsOfUse/> : <></>
                    }

                </div>
            </div>


            <div className="grid grid-cols-12 gap-x-6 px-6">
                <div className="col-span-10 col-start-2">
                </div>
            </div>

        </>

    )
}

const Copyright = () => {
    const {t,  i18n} = useTranslation()
    document.body.dir = i18n.dir()

    return (
        <>
            <h1 className="font-roboto font-bold text-4xl">{t('copyright.title')}</h1>

            <p className="font-bold uppercase">
                {t('copyright.subtitle')}
            </p>

            <hr/>
            <p className="font-light">
                {t('copyright.rights_reserved')}
            </p>
            <p className="font-light">
                {t('copyright.param1')} {t('copyright.term_conditions_link')} {t('copyright.param2')}
            </p>

            <p className="font-light">
                {t('copyright.param3')}
            </p>

            <p className="font-light">
                {t('copyright.param4')} {t('copyright.rights_permission_link')}
            </p>
        </>
    )
}


const FraudAlert = () => {
    const {t, i18n} = useTranslation()
    document.body.dir = i18n.dir()
    return (
        <>
            <h1 className="font-roboto font-bold text-4xl">{t('fraud_alert.title')}</h1>

            <p className="font-bold uppercase">
                {t('fraud_alert.subtitle')}
            </p>

            <hr/>
            <p className="font-light">
                {t('fraud_alert.param1')}
            </p>
            <p className="font-light">
                {t('fraud_alert.param2')}
            </p>

            <ul className="font-light text-base list-disc list-inside space-y-4">
                <li className="text-base">{t('fraud_alert.list1')}</li>
                <li>{t('fraud_alert.list2')}
                </li>
                <li>{t('fraud_alert.list3')}</li>
                <li>{t('fraud_alert.list4')}</li>
                <li>{t('fraud_alert.list5')}</li>
            </ul>

            <p className="font-light">{t('fraud_alert.param3')}</p>

            <p className="font-light italic">
                {t('fraud_alert.param4')}
            </p>
        </>
    )
}


const Privacy = () => {
    const {t, i18n} = useTranslation()
    document.body.dir = i18n.dir()
    return (
        <>
            <h1 className="font-roboto font-bold text-4xl">{t('privacy.title')}</h1>

            <p className="font-bold uppercase">
                {t('privacy.subtitle')}
            </p>

            <hr/>
            <p className="font-light">
                {t('privacy.all_rights_reserved')}
            </p>
            <p className="font-light">
                {t('privacy.param1')}
            </p>

            <p className="font-light">
                {t('privacy.param2')}
            </p>
        </>
    )
}


const TermsOfUse = () => {
    const {t, i18n} = useTranslation()
    document.body.dir = i18n.dir()
    return (
        <>
            <h1 className="font-roboto font-bold text-4xl">{t('terms.title')}</h1>

            <p className="font-bold uppercase">
                {t('terms.subtitle')}
            </p>

            <hr/>
            <p className="font-light">
                {t('terms.param1')}
            </p>

            <ol className="font-light text-base list-disc list-inside space-y-4">
                <li className="text-base">{t('terms.param2')}</li>
                <li>{t('terms.param3')}</li>
                <li>{t('terms.param4')}</li>
            </ol>
            <hr/>
            <h2 className="font-bold text-xl">
                {t('terms.disclaimers')}
            </h2>
            <p className="font-light">
                {t('terms.param5')}
            </p>
            <p className="font-light">{t('terms.param6')}</p>
            <p className="font-light">{t('terms.param7')}</p>
            <p className="font-light">{t('terms.param8')}</p>
            <p className="font-light">{t('terms.param9')}</p>
            <p className="font-light">{t('terms.param10')}</p>
            <ol className="font-light text-base list-disc list-inside space-y-4">
                <li className="text-base">{t('terms.list1')}</li>
                <li>{t('terms.list2')}</li>
                <li>{t('terms.list3')}</li>
                <li className="text-base">{t('terms.list4')}</li>
                <li>{t('terms.list5')}
                </li>
                <li>{t('terms.list6')}
                </li>
                <li>{t('terms.list7')}
                </li>
            </ol>
            <p className="font-light">{t('terms.param11')}</p>
            <p className="font-light">
                {t('terms.param12')}
            </p>

            <hr/>

            <h2 className="font-bold text-xl">
                {t('terms.param13')}
            </h2>
            <p className="font-light">{t('terms.param14')}</p>

            <hr/>

            <h2 className="font-bold text-xl">
                {t('terms.param15')}
            </h2>

            <p className="font-light">
                {t('terms.param16')}
            </p>
            <hr/>

            <h2 className="font-bold text-xl">
                {t('terms.param17')}
            </h2>
            <p className="font-light">
                {t('terms.param18')}
            </p>

            <h2 className="font-bold text-xl">
                {t('terms.param19')}
            </h2>
            <p className="font-light">
                {t('terms.param20')}

            </p>
            <p className="font-light">
                {t('terms.param21')}
            </p>
            <p className="font-light">
                {t('terms.param22')}
            </p>


        </>
    )
}
