import React, { useState, useEffect } from "react";
import { NavLink, Link, BrowserRouter as Router } from "react-router-dom";


import { slide as Menu } from "react-burger-menu";

import Logo from "../../../psdata-hub.png";
import UNLogo from '../../../assets/img/UN_logo_2022_emblem.png'
import LanguageSwitcherComponent from "../Dropdown";
import {useTranslation} from "react-i18next";
import LanguageBanner from "../LanguageBanner";

export default function NavBar() {
  const [isOpen, setIsOpen] = useState(false);
  const {t} = useTranslation();


  const handleIsOpen = () => {
    setIsOpen(!isOpen)
  }

  const closeMenu = () => {
    setIsOpen(false)
  }

  return (
    <div
      className="flex flex-row justify-center max-w-screen w-full fixed top-0 bg-white"
      style={{ zIndex: 40 }}
    >
      <nav className="container center m-auto text-roboto leading-tight text-base pr-8 h-20 md:space-y-0 space-y-4" >
        <div className="flex items-center">
          <Link
            onClick={(e) => e.currentTarget.blur()}
            role="button"
            exact="true"
            to="/"
            className="font-bold text-roboto flex flex-initial justify-start items-center w-full  px-4 h-20 bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 self-start"
          >
            <img src={UNLogo} alt="UN logo" width="98" />
            <div className="flex flex-col">
              <span className="text-3xl font-light">{t('navbar.peace_security')}</span>
              <span className="text-3xl font-extrabold">{t('navbar.data_hub')}</span>
            </div>
          </Link>
          <div className="lg:flex hidden items-center text-sm w-full  justify-end">
            <LanguageBanner />
          </div>

          <div className="lg:hidden">

          <Menu right disableAutoFocus isOpen={isOpen} onOpen={handleIsOpen} onClose={handleIsOpen}>
            <NavLink
              className="menu-item"
              exact={true}
              to="/"
              onClick={(e)=>closeMenu()}
              >
              Home
            </NavLink>
            <NavLink
              onClick={(e)=>closeMenu()}
              className="menu-item"
              to="/search">
              {t('footer.find_dataset')}
            </NavLink>
            <NavLink
              onClick={(e)=>closeMenu()}
              className="menu-item"
              to="/themes">
              {t('footer.browse_by_theme')}
            </NavLink>
            <NavLink
              className="menu-item"
              to="/howto"
              onClick={(e)=>closeMenu()}
            >
              {t('footer.how_to')}
            </NavLink>
            <NavLink
              className="menu-item"
              to="/stories"
              onClick={(e)=>closeMenu()}
              >
              {t('footer.data_stories')}
            </NavLink>
            <NavLink className="menu-item" to="/resources"
              onClick={(e)=>closeMenu()}
            >
              {t('home.additional_resources')}
            </NavLink>
            <NavLink className="menu-item" to="/about"
              onClick={(e)=>closeMenu()}
            >
              {t('home.about')}
            </NavLink>
            <LanguageBanner />
          </Menu>
          </div>
        </div>
      </nav>
    </div>
  );
}
