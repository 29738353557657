import React, { useState, useEffect, useRef } from "react";

import { InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";

import ScrollToTopBtn from "../components/Global/ScrollTop";

import { SearchResultContainer } from "../components/Search/SearchResultContainer";
import { SortComponent } from "../components/Search/SortComponent";
import { SearchBar } from "../components/Search/SearchBar";
import SidebarV2 from "../components/Search/SidebarV2";

import Fuse from "fuse.js";

import CountryFilter from "../components/Search/CountryFilterV2.js";
import MissionFilter from "../components/Search/MissionFilterV2.js";

import {
  fetchMetadataInternal,
  fetchMetadata,
} from "../shared/utils/fetchapi.js";

import {
  useFetchMetadataPublic,
  useFetchMetadataInternal,
} from "../shared/hooks/useFetchMetadata";
import {Trans, useTranslation} from "react-i18next";

const CACHE = {};

export default function SearchContainerV2() {
  const searchRef = useRef(null);

  const {t, i18n} = useTranslation()
  document.body.dir = i18n.dir()

  const { instance, inProgress } = useMsal();
  const [accessToken, setAccessToken] = useState(null);
  // const metadata = useState(null);
  const [fetchedData, setFetchedData] = useState(false);
  const [metadata, setMetadata] = useState(null);
  const [displayData, setDisplayData] = useState(metadata);
  const [filteredDisplayData, setFilteredDisplayData] = useState(null);

  const [activeSort, setActiveSort] = useState(null);

  const [searchQuery, setSearchQuery] = useState([]);

  const [typeFilter, setTypeFilter] = useState(null);

  const [showCountryFilter, setShowCountryFilter] = useState(false);
  const [showMissionFilter, setShowMissionFilter] = useState(false);

  const [isSorted, setIsSorted] = useState(false);

  const [selectedFilters, setSelectedFilters] = useState({
    themes: [],
    missions: [],
    search: [],
    countries: [
      { Asia: [] },
      { Europe: [] },
      { Africa: [] },
      { Oceania: [] },
      { Americas: [] },
      { Antarctica: [] },
    ],
  });

  const [searchBarQuery, setSearchBarQuery] = useState([])

  const [joinedFilters, setJoinedFilters] = useState([]);

  /* FUSE SEARCH OPTIONS */

  const searchOptions = {
    shouldSort: true,
    threshold: 0.05,
    findAllMatches: true,
    ignoreLocation: true,
    minMatchCharLength: 3,
    matchAllTokens: true,
    tokenize: true,
    includeScore: true,
    keys: [
      {
        name: "Name",
        weight: 0.1,
      },
      {
        name: "Description",
        weight: 0.1,
      },
      {
        name: "Themes.Theme",
        weight: 0.1,
      },
      {
        name: "Missions.Mission",
        weight: 0.1,
      },
      {
        name: "Countries.Country",
        weight: 0.1,
      },
      // {
      //   name: "all",
      //   weight: 0.3,
      // },
    ],
  };

  const fuse = new Fuse(metadata ? metadata : [], searchOptions);

  useEffect(() => {
    const accessTokenRequest = {
      scopes: [
        process.env.REACT_APP_MSAL_API_SCOPE ||
          "https://api-psdatadev.un.org/api.call",
        "user.read",
      ],
    };
    if (inProgress === InteractionStatus.None) {
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
          // Acquire token silent success
          let accessToken = accessTokenResponse.accessToken;
          setAccessToken(accessToken);
        })
        .catch(() => {
          setAccessToken(false);
        });
    }
  }, [instance, inProgress]);

  useEffect(() => {
    /*
     * Get Access Token
     * OR set Access Token null
     */
    if (accessToken) {
      fetchMetadataInternal("all", accessToken).then((d) => {
        setMetadata(d["data"]);
        setDisplayData(d["data"]);
        setFetchedData(true);
      });
    } else {
      fetchMetadata("all").then((d) => {
        setMetadata(d["data"]);
        setDisplayData(d["data"]);
        setFetchedData(true);
      });
    }
  }, [accessToken]);

  useEffect(() => {
    /*
     * fuse search to set data to be displayed
     */

    if (joinedFilters.length === 0) {
      setDisplayData(metadata);
      return;
    }

    const results = fuse.search(
      joinedFilters.length > 0 ? joinedFilters.join(" ") : " "
    );

    console.log(results);

    setDisplayData(results.map((d) => d.item).reverse());
  }, [joinedFilters]);

  useEffect(() => {
    /* If sorted filter again*/
    if (!typeFilter) {
      setFilteredDisplayData(null);
      return;
    }
    const filter = displayData.filter((d) => d.Type === typeFilter);
    setFilteredDisplayData(filter);
  }, [displayData, isSorted]);

  useEffect(() => {
    /*
     * Filter on "acces type"
     */
    if (!typeFilter) {
      setFilteredDisplayData(null);
      return;
    }
    const filter = displayData.filter((d) => d.Type === typeFilter);
    setFilteredDisplayData(filter);
  }, [typeFilter]);

  useEffect(() => {
    /*
     * Flatten filters
     */

    /* DESERIALIZE OBJ */
    let selectedFiltersOBJ =
      typeof selectedFilters === "string"
        ? JSON.parse(selectedFilters)
        : selectedFilters;
    let joinedFilters_ = [];
    for (const property in selectedFiltersOBJ) {
      if (property === "countries") {
        // console.log(selectedFiltersOBJ["countries"]);
        for (const continent in selectedFiltersOBJ["countries"]) {
          // console.log(continent);
          if (Array.isArray(selectedFiltersOBJ["countries"])) continue;
          joinedFilters_ = [
            ...joinedFilters_,
            ...selectedFiltersOBJ["countries"][continent].map((d) => d),
          ];
        }
      } else {
        joinedFilters_ = [
          ...joinedFilters_,
          ...selectedFiltersOBJ[property].map((d) => d),
        ];
      }
    }

    // console.log(selectedFilters, joinedFilters_);
    setJoinedFilters(joinedFilters_);
  }, [selectedFilters]);

  return (
    <>
      <div className="container center grid min-h-screen m-auto grid-cols-12 relative">
        <div className="col-span-3 h-full text-white md:block hidden">
          <div className="mt-16">
            <SidebarV2
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              setTypeFilter={setTypeFilter}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
              setShowCountryFilter={setShowCountryFilter}
              setShowMissionFilter={setShowMissionFilter}
            />
          </div>
        </div>

        {/*
         * MAIN CONTENT
         */}

        {showCountryFilter ? (
          <CountryFilter
            setSelectedFilters={setSelectedFilters}
            selectedFilters={selectedFilters}
            setShowCountryFilter={setShowCountryFilter}
          />
        ) : (
          <></>
        )}

        {showMissionFilter ? (
          <MissionFilter
            setSelectedFilters={setSelectedFilters}
            selectedFilters={selectedFilters}
            setShowMissionFilter={setShowMissionFilter}
          />
        ) : (
          <></>
        )}
        <div className="md:col-span-9 md:col-start-4 col-span-12 bg-neutral-95 p-8 bg-opacity-30 space-y-8">
          <div className="flex space-x-4 items-center">
            <div className="grow w-full">
              <SearchBar
                innerRef={searchRef}
                setSearchQuery={setSearchQuery}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                searchQuery={searchQuery}
              />
            </div>
            <div className="grow-0">
              <SortComponent
                displayData={displayData}
                setDisplayData={setDisplayData}
                isSorted={isSorted}
                setIsSorted={setIsSorted}
              />
            </div>
          </div>
          {filteredDisplayData && (
            <div><Trans i18nKey="generic.datasets_found" count={filteredDisplayData.length}>
               {t('generic.datasets_found')}
            </Trans> </div>
          )}
          {!filteredDisplayData && displayData && (
            <div>
              <Trans i18nKey="generic.datasets_found" count={displayData.length}>
                {t('generic.datasets_found')}
              </Trans>
            </div>
          )}
          <div>
            <SearchResultContainer
              fetchedData={fetchedData}
              metadata={filteredDisplayData ? filteredDisplayData : displayData}
            />
          </div>
        </div>
      </div>

      <div className="grid grid-cols-12 sticky bottom-10">
        <ScrollToTopBtn />
      </div>
    </>
  );
}
