import React, { useState, useEffect } from "react";

import { InteractionStatus } from "@azure/msal-browser";
import {AuthenticatedTemplate, useMsal} from "@azure/msal-react";

import Sidebar from "../components/Dataset/Sidebar.js";
import Metadata from "../components/Dataset/Metadata.js";
import DownloadComponent from "../components/Dataset/DownloadComponent.js";
import DiscoverComponent from "../components/Dataset/DiscoverComponent.js";
import Powerbi from "../components/Dataset/Visualize.js";

import Preview from "../components/Dataset/Preview";

import {
  fetchPage,
  fetchMetadata,
  fetchPageInternal,
  fetchMetadataInternal,
} from "../shared/utils/fetchapi.js";

import bannerRight from "../assets/img/dataset_banner-right.svg";
import bannerLeft from "../assets/img/dataset_banner-left.svg";

import { readString } from "react-papaparse";

import "./styles/Datasetpage.css";
import {BarChart} from "../components/Dataset/BarChart";
import ContactComponent from "../components/Dataset/ContactComponent";

export default function Datasetpage({ match }) {
  const { instance, inProgress } = useMsal();

  const [metadata, setMetadata] = useState({});
  const [data, setData] = useState("");
  const [convertedData, setConvertedData] = useState(null);
  const [fetchDataError, setFetchDataError] = useState(false);
  const [tab, setTab] = useState("metadata");

  const [accessToken, setAccessToken] = useState("");

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const dataset = match.params.dataset;

  let pageNum = 1;

  useEffect(() => {
    const accessTokenRequest = {
      scopes: [
        process.env.REACT_APP_MSAL_API_SCOPE ||
          "https://api-psdatadev.un.org/api.call",
        "user.read",
      ],
    };
    if (inProgress === InteractionStatus.None) {
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
          // Acquire token silent success
          let accessToken = accessTokenResponse.accessToken;
          setAccessToken(accessToken);
          setIsLoggedIn(true)
          // Call your API with token
          getMetadataInternal(accessToken);
        })
        .catch(() => {
          getMetadataPublic();
          getDataPublic(pageNum);
        });
    }
  }, [instance, inProgress]);


  useEffect(() => {
    if (metadata["Type"] === "Publicly Released") {
      getDataPublic(pageNum);
    }
    if (metadata["Type"] === "Restricted") {
      getDataInternal(pageNum, accessToken);
    }
  }, [metadata]);

  let getDataInternal = async (pageNum, accessToken) => {
    const response = await fetchPageInternal(
      dataset,
      "csv",
      pageNum,
      499,
      accessToken
    );
    try {
      if (response === undefined) {
        setFetchDataError(true);
        return;
      }
      setData(response.data);
    } catch (error) {
      console.error(error, "THERE WAS ANERROR");
      return;
    }
  };

  let getDataPublic = async (pageNum) => {
    const response = await fetchPage(dataset, "csv", pageNum, 499);
    try {
      if (response === undefined) {
        setFetchDataError(true);
        return;
      }
      setData(response.data);
    } catch (error) {
      console.error(error, "THERE WAS ANERROR");
      return;
    }
  };

  let getMetadataPublic = async () => {
    const response = await fetchMetadata(`${dataset}`);
    setMetadata(response.data[0]);
  };

  let getMetadataInternal = async (accessToken) => {
    const response = await fetchMetadataInternal(`${dataset}`, accessToken);
    setMetadata(response.data[0]);
  };

  useEffect(() => {
    const csv = readString(data, {
      header: true,
      dynamicTyping: true,
    });
    setConvertedData(csv.data);
  }, [data]);

  const isActive = (tabVal) => {
    return (
      "btn_sm bg-gray-500  hover:bg-gray-500 text-white transition-colors" +
      (tabVal === tab
        ? "btn_shadow hover:text-white"
        : "text-white text-white bg-opacity-50")
    );
  };

  const Tabs = () => {
    return (
      <div className="flex flex-row items-center space-x-12">
        <span className="text-xs text-gray-800">Show</span>
        <button
          onClick={(e) => setTab("metadata")}
          className={isActive("metadata")}
        >
          Metadata
        </button>
        {convertedData !== null ? (
          <button
            onClick={(e) => setTab("datapreview")}
            className={isActive("datapreview")}
          >
            Data Preview
          </button>
        ) : (
          <></>
        )}
        {metadata["Visualization Link"] !== null ? (
          <button
            onClick={(e) => setTab("visualize")}
            className={isActive("visualize")}
          >
            Visualize
          </button>
        ) : (
          <></>
        )}

        <button
          onClick={(e) => setTab("download")}
          className={isActive("download")}
        >
          Download
        </button>
        {metadata['Analytics Request'] ?
        <AuthenticatedTemplate>
          <button
              onClick={(e) => setTab("analytics")}
              className={isActive("analytics")}
          >
            Usage Statistics
          </button>
        </AuthenticatedTemplate>
            : ''}
        {/*<button
            onClick={(e) => setTab("contact")}
            className={isActive("contact")}
        >
          Contact the contributor
        </button>*/}
      </div>
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="sticky relative z-10" style={{ top: "5rem" }}>
        <div className="md:hidden">
          <select
            className="w-full h-20"
            onChange={(e) => setTab(e.target.value)}
          >
            <option value="metadata">Metadata</option>
            <option value="download">Download</option>
          </select>
        </div>
        <div className="container mx-auto hidden md:flex flex-row bg-menu-hover justify-center items-center" style={{ height: 72 }}>
          <Tabs />
        </div>
      </div>
      <div className="container center grid m-auto grid-cols-12 gap-x-6 ">
        <div className="hidden md:block col-span-3 bg-neutral-95 bg-opacity-30 px-6 pt-5 border-r border-neutral-76 pb-36">
          {metadata && <Sidebar metadata={metadata} />}
        </div>
        <div className="md:col-span-9 col-span-12 md:col-start-4 md:px-8 px-4 py-6 md:ml-6 gap-x-6 mb-10">
          <div className="col-span-8 mx-auto">
            {metadata && tab === "metadata" && <Metadata metadata={metadata} />}

            {tab === "datapreview" && convertedData && (
              // <BasicSort dataset={data} />
              <Preview
                dataset={convertedData}
                definitions={metadata["Column definitions"]}
              />
            )}

            {tab === "download" && (
              <DownloadComponent
                id={metadata["Dataset ID"]}
                accessToken={accessToken}
                isLoggedIn={isLoggedIn}
                accessType={
                  metadata["Type"] === "Publicly Released"
                    ? "public"
                    : "internal"
                }
              />
            )}

            {tab === "contact" && (
                <ContactComponent dataset_id={metadata["Dataset ID"]} data_steward={metadata['Data Steward'][0]['Email']} />
            )}

            {tab === "visualize" &&
              convertedData &&
              fetchDataError === false && (
                <Powerbi link={metadata["Visualization Link"]} />
              )}

            {tab === "analytics" && (
              <BarChart title={metadata['Name']} analytics={metadata['Analytics Request'] ? metadata['Analytics Request'] : ''} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
