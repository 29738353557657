import './style.css'
import {useState, useEffect} from 'react';
import LinkButton from '../../Global/LinkButton';
import Flag from '../../../assets/img/stock/flag.jpg';

import {useTranslation} from "react-i18next";

export const Hero = ({mainList}) => {

    const {t, i18n} = useTranslation();
    document.body.dir = i18n.dir()

    return (
        <>
            <div id="hero" className="w-full">
                <div className="mx-auto relative overflow-hidden">
                    <div className="grid grid-cols-12 h-full  gap-x-6 container mx-auto px-8">
                        <div className="col-span-7 flex items-center">
                            <div className="grid grid-cols-7 gap-x-6 ">
                                <div className="col-span-1 h-full md:flex flex-col hidden">
                                    <div className="flex flex-col space-y-2 pt-4">
                                        <span className="text-sm text-neutral-20">{t('home.datasets')}</span>
                                        <span className="text-sapphire-blue font-bold">{mainList.length > 0 ?
                                            <CountUpAnimation>{mainList.length}</CountUpAnimation> : ""
                                        }</span>
                                    </div>
                                </div>
                                <div className="col-span-12 md:col-span-6 h-full flex flex-col justify-center">
                                    <div className="space-y-8 unorg-home-hero">
                                        <h1>{t('home.hero')}</h1>
                                        <p className="text-lg font-light">{t('home.slogan')} </p>
                                        <LinkButton to="/search/"
                                                    className="btn_lg uppercase whitespace-nowrap bg-un-blue text-white hover:bg-sapphire-blue transition-colors">{t('home.link_button')}</LinkButton>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="md:block hidden col-span-4 self-end h-2/3">
                            <div className="flex items-center justify-center overflow-hidden h-full"
                                 style={{borderRadius: 45}}>
                                <img className="" style={{width: "100%", height: "600px", objectFit: "cover"}}
                                     src={Flag} alt=""/>
                            </div>

                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

const easeOutQuad = t => t * (2 - t);
const frameDuration = 1000 / 60;

const CountUpAnimation = ({children, duration = 2000}) => {
    const countTo = parseInt(children, 10);
    const [count, setCount] = useState(0);

    useEffect(() => {
        let frame = 0;
        const totalFrames = Math.round(duration / frameDuration);
        const counter = setInterval(() => {
            frame++;
            const progress = easeOutQuad(frame / totalFrames);
            setCount(countTo * progress);

            if (frame === totalFrames) {
                clearInterval(counter);
            }
        }, frameDuration);
    }, []);

    return Math.floor(count);
};

