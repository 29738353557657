import React, { useState, useEffect } from "react";
import RightArrow from "../../assets/right_arrow_purple.svg";
import CloseButton from "../../assets/x_close.svg";

import { fetchMissionList } from "../../shared/utils/fetchapi";

const CACHE = {};

/**
 * Filters datasets by tagged mission
 *
 * @param {string} setMissionQuery Missions to be query
 * @param {Boolean} setShowMissionFilter State of mission filters
 */
export default function MissionFilter(
  { setMissionQuery,  setShowMissionFilter }
) {
  const [missionTab, setMissionTab] = useState("All");
  const [missions, setMissions] = useState([]);
  const [allMissions, setAllMissions] = useState([]);
  const [selectedMissions, setSelectedMissions] = useState([]);

  // Alphabetically sorts Missions
  const GetSortOrder = (prop) => {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  };

  const getMissions = async () => {
    if (CACHE.missions !== undefined) {
      setMissions(CACHE.missions);
      setAllMissions(CACHE.allMissions);
      setSelectedMissions(CACHE.selectedMissions);
      return;
    }


    const missionList = await fetchMissionList();

    try {
      let sortedMissionList = missionList.data.sort(GetSortOrder("mission_acronym"));
      let missions_ = {};
      let selectedMissions_ = {};

      CACHE.allMissions = sortedMissionList;
      setAllMissions(sortedMissionList);

      const distinctMissionType = [
        ...new Set(sortedMissionList.map((x) => x["mission_type"])),
      ];



      for (let missionType in distinctMissionType) {
        // console.log(distinctMissionType[missionType])
        let missions = sortedMissionList.filter(
          (m) => m["mission_type"] === distinctMissionType[missionType]
        );
        missions_[distinctMissionType[missionType]] = missions;
        selectedMissions_[distinctMissionType[missionType]] = [];
      }

      CACHE.missions = missions_;
      setMissions(missions_);
      setSelectedMissions(selectedMissions_);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    window.scrollTo(0,0);
    getMissions();
  }, []);

  /**
   * Handle selecting a mission and adding it to missions to filter for/ remove if previously selected
   * @param {string} e Mission that is selected
   * @param {string} c Missions that are already selected
   */
  const addSelectedMissions = (e, c) => {
    const selectedMission_ = e;
    const selectedMissionType_ = c;

    let missionListToUpdate = selectedMissions;
    if (
      missionListToUpdate[selectedMissionType_].includes(selectedMission_) === false ||
      missionListToUpdate === undefined
    ) {
      missionListToUpdate[selectedMissionType_].push(selectedMission_);
    } else {
      const index = missionListToUpdate[selectedMissionType_].indexOf(
        selectedMission_
      );
      missionListToUpdate[selectedMissionType_].splice(index, 1);
    }


    CACHE.selectedMissions = { ...missionListToUpdate };
    setSelectedMissions({ ...missionListToUpdate });
  };

  useEffect(() => {
    let checkboxes = document.getElementsByClassName("missioncheckbox");
    if (selectedMissions === undefined) {
      return;
    }
    Object.keys(selectedMissions).map((i) => {
      selectedMissions[i].map((j) => {
        for (let i = 0; i < checkboxes.length; i++) {
          if (checkboxes[i].name === j) {
            let input_ = document.getElementsByName(checkboxes[i].name)[0];
            input_.checked = true;
          }
        }
      });
    });
  }, [selectedMissions]);

  // Logic for Select All button
  const addAllBtn = (e) => {
    let checkboxes = document.getElementsByClassName("missioncheckbox");
    for (let i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].checked === false) {
        checkboxes[i].click();
      }
      checkboxes[i].checked = true;
    }
  };

  const clearAllBtn = (e) => {
    let checkboxes = document.getElementsByClassName("missioncheckbox");
    for (let i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].checked === true) {
        checkboxes[i].click();
      }
      checkboxes[i].checked = false;
    }
  };

  // Closing menu will update dataset page
  const handleClose = () => {
    let passToParent = [];
    if (selectedMissions === undefined) {
      setMissionQuery(passToParent);
      setShowMissionFilter(false);
      return;
    }
    Object.keys(selectedMissions).map((i) => {
      for (let j in selectedMissions[i]) {
        passToParent.push(selectedMissions[i][j]);
      }
    });
    setMissionQuery(passToParent);
    setShowMissionFilter(false);
  };

  // Imports countries list file and stores them

  return (
    <>
      <div className="col-span-9 col-start-4 bg-white p-8 space-y-8 sticky">
        <div className="grid grid-cols-9 bg-white">
          <div className="col-span-2">
            <div className="">
              <div className="flex flex-col space-y-4 text-roboto mt-6">
                <span className="text-xs ">Missions</span>
                <span
                  role="button"
                  className={
                    missionTab === "All"
                      ? "font-normal text-persian-indigo"
                      : "font-light"
                  }
                  onClick={(e) => {
                    setMissionTab("All");
                  }}
                >
                  <div className="flex justify-between items-center mr-8">
                    <span>All</span>
                    <span className={missionTab === "All" ? "block" : "hidden"}>
                      <img src={RightArrow} alt="" />
                    </span>
                  </div>
                </span>
                <hr />

                {Object.keys(missions).map((i, k) => {
                  return (
                    <>
                      <span className="text-xs ">Missions</span>
                      {/* <span className="text-base" role="button" onClick={(e)=>setMissionTab(i)}>{i}</span> */}
                      <span
                        role="button"
                        className={
                          missionTab === i
                            ? "font-normal text-persian-indigo"
                            : "font-light"
                        }
                        onClick={(e) => {
                          setMissionTab(i);
                        }}
                      >
                        <div className="flex justify-between items-center mr-8">
                          <span>
                            {i}{" "}
                            {selectedMissions !== undefined ? (
                              selectedMissions[i] !== undefined &&
                              selectedMissions[i].length > 0 ? (
                                `(${selectedMissions[i].length})`
                              ) : (
                                <></>
                              )
                            ) : (
                              <></>
                            )}
                          </span>
                          <span
                            className={missionTab === i ? "block" : "hidden"}
                          >
                            <img src={RightArrow} alt="" />
                          </span>
                        </div>
                      </span>
                      <hr />
                      {/* <hr className="mr-8" /> */}
                    </>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="col-span-7 p-6 mx-8 border-l border-neutral-95">
            <div className="flex space-x-4 mb-8 justify-between">
              <div className="space-x-4">
                <button
                  className="text-xs text-roboto font-bold"
                  onClick={addAllBtn}
                >
                  Select All
                </button>
                <button
                  className="text-xs text-roboto font-bold text-neutral-76"
                  onClick={clearAllBtn}
                >
                  Remove All
                </button>
              </div>
              <button
                onClick={handleClose}
                className="flex space-x-2 items-center"
              >
                <span className="text-sm">Close</span>{" "}
                <img src={CloseButton} alt="" />
              </button>
            </div>
            {missionTab === "All" && (
              <MissionList
                missions={allMissions}
                addSelectedMissions={addSelectedMissions}
              />
            )}

            {missionTab !== "All" && (
              <MissionList
                missions={missions[missionTab]}
                addSelectedMissions={addSelectedMissions}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

const MissionList = (props) => {
  let alphabetized = {};

  props.missions.map((c) => {
    if (alphabetized.hasOwnProperty(c.mission_acronym[0])) {
      let key = c.mission_acronym[0];
      alphabetized[key].push({
        Title: c.mission_acronym,
        "mission_acronym": c["mission_acronym"],
        "mission_type": c["mission_type"]
      });
    } else {
      let firstLetter = c.mission_acronym[0].toString();
      alphabetized[firstLetter] = [
        { Title: c.mission_acronym, "mission_acronym": c["mission_acronym"], "mission_type": c["mission_type"] },
      ];
    }
  });

  return (
    <>
      {Object.keys(alphabetized).map((i) => {
        return (
          <div>
            <p className="text-sm text-un-blue">{i}</p>
            <hr className="border-un-blue my-2" />
            <ul className="mb-6">
              {alphabetized[i].map((m, key) => {
                return (
                  <li className="my-2" key={key}>
                    <label className="inline-flex items-center">
                      <input
                        onChange={() =>props.addSelectedMissions(m["mission_acronym"], m["mission_type"])} type="checkbox" className="missioncheckbox rounded-full text-persian-indigo focus:ring-persian-indigo h-4 w-4" name={m.mission_acronym} key={key} />
                      <span className="ml-2 text-xs">{m.mission_acronym}</span>
                      </label>
                    </li>
                  )
                }
            )}
            </ul>
          </div>
        )
      })
    }
    </>
  )
}