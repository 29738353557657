import {Link}from 'react-router-dom';

import Popup from 'reactjs-popup';

import {useEffect} from 'react';
import './styles/HowTo.css'


import StockImg from '../assets/img/stock/HowTo.png';
import SearchModal from '../assets/img/howto/How_to_1.png';
import ExploreI from '../assets/img/howto/How_to_2.png';
import ExploreImg from '../assets/img/howto/How_to_3.png';
import DownloadImg from '../assets/img/howto/How_to_5.png';
import MetadataImg from '../assets/img/howto/How_to_6.png';

import expand from '../assets/img/howto/expand.png';
import {useTranslation} from "react-i18next";

export default function HowTo () {

  const {t} = useTranslation();

  return (
    <>
    <div className="h-screen w-full absolute  bg-cover  " style={{height: 500,}}></div>
      <div className="container relative mx-auto header grid grid-cols-12 gap-x-6 px-6 overflow-hidden mt-auto"
           style={{"height": "300px"}}>
        <div
            className="col-span-12 md:col-span-4 col-start-2 flex flex-col mt-10 space-y-8 unorg-home-hero">
        <h1 className="font-bold">{t('how_to.title')}</h1>
        <p >{t('how_to.quick_guide')}</p>
      </div>
      <div className="hidden md:block col-span-4 col-start-8 relative">
        <div className="right-0 -bottom-20 h-5/6 overflow-hidden left-0 mt-10">
          <div className="h-full">
            <img src={StockImg} alt="" style={{borderRadius: 45, objectFit: "cover", width:"100%", height:"100%"}}/>
          </div>
        </div>
      </div>
    </div>


    <div className="container mx-auto grid grid-cols-12 py-16 gap-x-6">
      <div className="hidden md:block col-span-2 col-start-2">
        <Navigation />
      </div>
      <div className="col-span-9 md:col-span-7 col-start-2 md:col-start-4 unorg-home-hero">
        <TagLine />
        <hr className="border-neutral-30 my-12" />
        <h4>{t('how_to.quick_tips_title')}</h4>
        <div className="space-y-8">
          <Search />
          <Explore />
          <GetTheData/>
          <Metadata/>
        </div>
        <hr className="border-neutral-30 my-12" />
        <h4 className="font-bold mb-12">{t('how_to.tech_tutorials_title')}</h4>
        <div className="space-y-8">
          <UseTheData />
          <BuildApi />
        </div>
        <hr className="border-neutral-30 my-12" />
        <h4 className="font-bold mb-12">{t('how_to.more_questions')}</h4>
        <Contact />
      </div>
    </div>



    </>

  )
}


const Navigation = () =>{
  useEffect(()=>{
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
        e.preventDefault();

        document.querySelector(this.getAttribute('href')).scrollIntoView({
            behavior: 'smooth',
            top:20
        });
    });
  });

  }, []);
  const {t} = useTranslation()

  useEffect(()=>{
    let mainNavLinks = document.querySelectorAll(".anchor-link");
    let mainSections = document.querySelectorAll(".anchor");

window.addEventListener("scroll", event => {
  let fromTop = window.scrollY - 205;

  mainNavLinks.forEach(link => {
    let section = document.querySelector(link.hash);
    if (section === undefined || section === null){return}
    if (
      section.offsetTop <= fromTop + 305 &&
      section.offsetTop + section.offsetHeight > fromTop + 305
    ) {
      link.classList.add("current");
      // console.log(link)
    } else {
      link.classList.remove("current");
    }
  });
});
  }, [])
  return (
    <div className="sticky" style={{top:120}}>
      <ul className="text-black space-y-2">
        <li className="uppercase font-bold text-sm">{t('how_to.quick_tips')}</li>
        <li className=""><a className="anchor-link"  href="#search">{t('how_to.search')}</a></li>
        <li className=""><a className="anchor-link" href="#explore">{t('how_to.explore')}</a></li>
        <li className=""><a className="anchor-link"  href="#getthedata">{t('how_to.get_data')}</a></li>
        <li className=""><a className="anchor-link"  href="#metadata">{t('how_to.metadata')}</a></li>
        <li className="uppercase font-bold text-sm pt-4">{t('how_to.technical_tutorials')}</li>
        <li className=""><a className="anchor-link"  href="#usethedata">{t('how_to.pull_data')}</a></li>
        <li className=""><a className="anchor-link"  href="#usetheapi">{t('how_to.api_structure')}</a></li>
        <li className="uppercase font-bold text-sm pt-4">{t('how_to.more_questions')}</li>
        <li className=""><a  className="anchor-link" href="#contact">{t('how_to.contact')}</a></li>
      </ul>
    </div>
  )
}

const TagLine = () => {
  const {t, i18n} = useTranslation();

  return (
    <>
      <h4 className="font-light">{t('how_to.slogan')} </h4>
    </>
  )
}

const Search = () => {

  const {t} = useTranslation();

  return (
    <div id="search" className="text-black anchor">
      <div className="grid grid-cols-7 gap-x-6">
        <div className="md:col-span-2 col-span-7 rounded-2xl">
          <div className="relative flex flex-col">

          <Popup
          trigger={<div role="button" className="">
            <div className="absolute w-full h-full bg-black opacity-0 hover:opacity-60 flex items-center justify-center transition-opacity rounded-2xl">
              <img className="h-2/3" src={expand} alt=""/>
            </div>
            <div className="bg-black w-1/2 h-full"></div><img className="rounded-2xl" src={SearchModal} alt="" /></div>}
          modal
          nested
          >
      {close => (
      <>
      <div className="modal z-40 md:w-1/2 w-full md:m-auto relative">
        <button className="close -mt-10 space-x-4" onClick={close}>
          <span>close</span><div className="text-black bg-white rounded-full h-6 w-6 ">&times;</div>
        </button>
        <div className="content z-40 ">
          <img className="rounded-2xl"  src={SearchModal} alt="Screenshot of search page" />
        </div>
      </div>
      </>
    )}
  </Popup>

          </div>
        </div>
        <div className="col-span-7 md:col-span-4 space-y-4">
          <h5 className="font-bold text-lg">{t('how_to.search')}</h5>
          <p className="text-base font-light">1. {t('how_to.already_have_idea')} <Link to="search" className="text-sapphire-blue">{t('how_to.find_dataset')}</Link> {t('how_to.already_have_idea_p2')} <Link to="/themes" className="text-sapphire-blue">{t('how_to.browse_by_theme')}</Link> {t('how_to.already_have_idea_p3')}</p>
          <p className="text-base font-light">2. {t('how_to.use_filters')} <Link to="/search" className="text-sapphire-blue">{t('how_to.find_dataset')}</Link>{t('how_to.use_filters_p2')}</p>
        </div>
      </div>
    </div>
  )
}


const Explore = () => {

  const {t} = useTranslation()

  return (
    <div id="explore" className="text-black anchor">
      <div className="grid grid-cols-7 gap-x-6">
        <div className="col-span-7 md:col-span-2 rounded-2xl">
          <div className="relative flex flex-col">

          <Popup
          trigger={<div role="button" className="">
            <div className="absolute w-full h-full bg-black opacity-0 hover:opacity-60 flex items-center justify-center transition-opacity rounded-2xl">
              <img className="h-2/3" src={expand} alt=""/>
            </div>
            <div className="bg-black w-full h-full"></div><img className="rounded-2xl" src={ExploreI} alt="" /></div>}
          modal
          nested
          >
      {close => (
      <>
      <div className="modal z-40 md:w-1/2 w-full md:m-auto relative">
        <button className="close -mt-10 space-x-4" onClick={close}>
          <span>close</span><div className="text-black bg-white rounded-full h-6 w-6 ">&times;</div>
        </button>
        <div className="content z-40">
          <img className="rounded-2xl" src={ExploreI} alt="Screenshot of search page" />
        </div>
      </div>
      </>
    )}
  </Popup>

          </div>
        </div>
        <div className="col-span-7 md:col-span-4 space-y-4">
          <h5 className="font-bold text-lg">{t('how_to.explore')}</h5>
          <p className="text-base font-light">3. {t('how_to.not_sure')} <Link to="/themes" className="text-sapphire-blue">{t('how_to.browse_by_theme')}</Link> {t('how_to.to_get_idea')} <Link to="/stories" className="text-sapphire-blue hover:underline">{t('footer.data_stories')}</Link> {t('how_to.for_illustrations')} </p>
        </div>
      </div>
    </div>
  )
}
const GetTheData = () => {

  const {t, i18n} = useTranslation()
  document.body.dir = i18n.dir()

  return (
    <div id="getthedata" className="text-black anchor">
      <div className="grid grid-cols-7 gap-x-6">
        <div className="col-span-7 md:col-span-2 rounded-2xl">
          <div className="relative flex flex-col">

          <Popup
          trigger={<div role="button">
            <div className="absolute w-full h-full bg-black opacity-0 hover:opacity-60 flex items-center justify-center transition-opacity rounded-2xl">
              <img className="h-2/3" src={expand} alt=""/>
            </div>
            <img className="rounded-2xl" src={ExploreImg} alt="" z/>
            </div>}
          modal
          nested
          >
      {close => (
      <>
      <div className="modal z-40 md:w-1/2 w-full md:m-auto relative">
        <button className="close -mt-10 space-x-4" onClick={close}>
          <span>close</span><div className="text-black bg-white rounded-full h-6 w-6 ">&times;</div>
        </button>
        <div className="content z-40">
          <img className="rounded-2xl"  src={ExploreImg} alt="Screenshot of search page" />
        </div>
      </div>
      </>
    )}
  </Popup>
          </div>
        </div>
        <div className="col-span-7 md:col-span-4 space-y-4">
          <h5 className="font-bold text-lg">{t('how_to.get_data')}</h5>
          <p className="text-base font-light">4. {t('how_to.get_data_description')} </p>
        </div>
      </div>
    </div>
  )
}

const Metadata = () => {

  const {t} = useTranslation()

  return (
    <div id="metadata" className="text-black anchor">
      <div className="grid grid-cols-7 gap-x-6">
        <div className="col-span-7 md:col-span-2 rounded-2xl">
          <div className="relative flex flex-col space-y-6">

          <Popup
          trigger={<div role="button">
            <div className="absolute w-full  h-1/2 bg-black opacity-0 hover:opacity-60 flex items-center justify-center transition-opacity rounded-2xl">
              <img className="h-16" src={expand} alt=""/>
            </div>
            <img className="rounded-2xl" src={DownloadImg} alt="" /></div>}
          modal
          nested
          >
      {close => (
      <>
      <div className="modal z-40 md:w-1/2 w-full md:m-auto relative">
        <button className="close -mt-10 space-x-4" onClick={close}>
          <span>close</span><div className="text-black bg-white rounded-full h-6 w-6 ">&times;</div>
        </button>
        <div className="content z-40">
          <img className="rounded-2xl" src={DownloadImg} alt="Screenshot of search page" />
        </div>
      </div>
      </>
    )}
  </Popup>
          <Popup
          trigger={<div role="button">

            <div className="absolute w-full  h-1/2 bg-black opacity-0 hover:opacity-60 flex items-center justify-center transition-opacity rounded-2xl">
              <img className="h-16" src={expand} alt=""/>
            </div>

            <img className="rounded-2xl" src={MetadataImg} alt="" /></div>}
          modal
          nested
          >
      {close => (
      <>
      <div className="modal z-40 md:w-1/2 w-full md:m-auto relative">
        <button className="close  -mt-10 space-x-4" onClick={close}>
          <span>close</span><div className="text-black bg-white rounded-full h-6 w-6 ">&times;</div>
        </button>
        <div className="content z-40">
          <img className="rounded-2xl" src={MetadataImg} alt="Screenshot of search page" />
        </div>
      </div>
      </>
    )}
  </Popup>
          </div>
        </div>
        <div className="col-span-7 md:col-span-4 space-y-4">
          <h5 className="font-bold text-lg">{t('how_to.metadata_key_source')}</h5>
          <p className="text-base font-light">5. {t('how_to.metadata_about_data')} </p>
          <p className="text-base font-light">6. {t('how_to.metadata_complex')}</p>
        </div>
      </div>
    </div>
  )
}

const UseTheData = () => {
  const {t} = useTranslation()

  return (
    <div id="usethedata" className="text-black anchor">
      <div className="grid grid-cols-7 gap-x-6">
        <div className="col-span-7 space-y-4">
          <h5 className="font-bold text-lg">{t('how_to.use_the_data_title')}</h5>
          <p className="text-base font-light">{t('how_to.use_the_data_description')} </p>

          <p className="text-xl font-light">
            {t('how_to.use_the_data_json_api')}
          </p>
          <hr/>
          <div className="text-xl font-light flex space-x-4">
            <div className="flex space-x-4 ">
              <Link to="/howto/excel_json" className="font-bold text-sm button_secondary"><span>{t('how_to.use_the_data_json_excel')}</span></Link>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="12" fill="#FBE11C"/><path d="M9.5 14.9401V9.05991C9.5 8.62843 9.91802 8.35959 10.2509 8.577L14.7523 11.5171C15.0826 11.7328 15.0826 12.2672 14.7523 12.4829L10.2509 15.423C9.91802 15.6404 9.5 15.3716 9.5 14.9401Z" fill="white"/></svg>
            </div>
            <div className="flex space-x-4">
              <Link to="/howto/powerbi_json" className="font-bold text-sm button_secondary"><span>{t('how_to.use_the_data_xml_api')}</span></Link>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="12" fill="#FBE11C"/><path d="M9.5 14.9401V9.05991C9.5 8.62843 9.91802 8.35959 10.2509 8.577L14.7523 11.5171C15.0826 11.7328 15.0826 12.2672 14.7523 12.4829L10.2509 15.423C9.91802 15.6404 9.5 15.3716 9.5 14.9401Z" fill="white"/></svg>
            </div>
          </div>

          <p className="text-xl font-light">
            XML API
          </p>
          <hr/>
          <div className="text-xl font-light flex space-x-4">
            <div className="flex space-x-4 ">
              <Link to="/howto/excel_xml" className="font-bold text-sm button_secondary"><span>{t('how_to.use_the_data_xml_excel')}</span></Link>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="12" fill="#FBE11C"/><path d="M9.5 14.9401V9.05991C9.5 8.62843 9.91802 8.35959 10.2509 8.577L14.7523 11.5171C15.0826 11.7328 15.0826 12.2672 14.7523 12.4829L10.2509 15.423C9.91802 15.6404 9.5 15.3716 9.5 14.9401Z" fill="white"/></svg>
            </div>
            <div className="flex space-x-4">
              <Link to="/howto/powerbi_xml" className="font-bold text-sm button_secondary"><span>{t('how_to.use_the_data_xml_powerbi')}</span></Link>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="12" fill="#FBE11C"/><path d="M9.5 14.9401V9.05991C9.5 8.62843 9.91802 8.35959 10.2509 8.577L14.7523 11.5171C15.0826 11.7328 15.0826 12.2672 14.7523 12.4829L10.2509 15.423C9.91802 15.6404 9.5 15.3716 9.5 14.9401Z" fill="white"/></svg>
            </div>
          </div>
        </div>
      </div>
    </div>


  )
}

const BuildApi = () => {
  const {t} = useTranslation()

  return (
    <div className="anchor" id="usetheapi">
      <div className="grid grid-cols-7 gap-x-6">
        <div className="col-span-7 space-y-4 anchor">
          <h5 className="font-bold text-lg">{t('how_to.build_api_title')}</h5>
          <p className="text-base font-light">{t('how_to.build_api_apis_created')} <a href="https://swagger.io/specification/" target="_blank" className="text-sapphire-blue">OpenAPIFramework v3.0.1</a> {t('how_to.build_api_apis_created_specifications')} </p>

            <div className="flex space-x-4">
              <Link to="/howto/api_structure" className="font-bold text-sm button_secondary"><span>{t('how_to.build_api_how_to')}</span></Link>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="12" fill="#FBE11C"/><path d="M9.5 14.9401V9.05991C9.5 8.62843 9.91802 8.35959 10.2509 8.577L14.7523 11.5171C15.0826 11.7328 15.0826 12.2672 14.7523 12.4829L10.2509 15.423C9.91802 15.6404 9.5 15.3716 9.5 14.9401Z" fill="white"/></svg>
            </div>
        </div>
      </div>
    </div>
  )
}

const Contact = () => {

  useEffect(()=>{
    window.scrollTo(0, 0);
  }, [])

  const {t} = useTranslation()

  return (
    <div className="grid grid-cols-7 anchor mb-80" id="contact">
      <div className="col-span-7 space-y-4">
        <h5 className="font-bold text-lg">{t('how_to.contact_title')}</h5>
        <p className="text-base font-light">{t('how_to.contact_want_to_learn')} <Link className="text-sapphire-blue" to='/contact'>{t('about.contact_us')}</Link> {t('how_to.contact_back_to_you')} </p>
        <div className="uppercase flex space-x-4">
          <Link to="/contact" className="font-bold text-sm button_secondary"><span>{t('about.contact_us')}</span></Link>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="12" fill="#FBE11C"/><path d="M9.5 14.9401V9.05991C9.5 8.62843 9.91802 8.35959 10.2509 8.577L14.7523 11.5171C15.0826 11.7328 15.0826 12.2672 14.7523 12.4829L10.2509 15.423C9.91802 15.6404 9.5 15.3716 9.5 14.9401Z" fill="white"/></svg>
        </div>
      </div>
    </div>

  )
}

