import NavBar from '../../components/Global/NavBar';
import BackgroundImage from '../../assets/img/NotFound.png';
import LinkButton from '../../components/Global/LinkButton'

export default function NotFound() {
    return (
        <>
        <NavBar />
        <div className="h-screen container mx-auto grid grid-cols-12 relative px-6 gap-x-6">
            <div className="absolute" style={{zIndex: -1}}>
                <img src={BackgroundImage} alt="" />
            </div>
            <div className="col-span-4 col-start-8">
                <div className="flex flex-col justify-center h-full">
                    <div className="space-y-8">
                        <p className="text-7xl font-bold text-sapphire-blue text-europa">Hmm...</p>
                        <p>It seems that you’re lost in the data maze. Let our data help guide you to the right path!</p>
                        <div className="flex flex-row w-full justify-between">
                            <button className="btn_lg text-white bg-un-blue hover:opacity-70 transition-opacity">click to go back</button>
                            <LinkButton className="btn_md text-black bg-jonquil hover:opacity-70 transition-opacity" to="/">Go to home</LinkButton>
                        </div>
                    </div>

                </div>
            </div>


        </div>
        </>
    )
}