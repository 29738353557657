import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {sendContributorEmail} from "../../shared/utils/fetchapi";

import {omit} from 'lodash'

let total = 0;

export default function ContactComponent({dataset_id, data_steward}) {

    const {t} = useTranslation();

    const [fullName, setFullName] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [message, setMessage] = useState("");
    const [datasetId, setDatasetId] = useState(dataset_id);
    const [dataSteward, setDataSteward] = useState(data_steward);


    const [mathValue, setMathValue] = useState("")
    const [totalValue, setTotalValue] = useState("")
    const [errors, setErrors] = useState({});
    const [messages, setMessages] = useState({})

    useEffect(() => {
        window.scrollTo(0, 0);
        createSum()
    }, [])

    const sanitizeText = (value) => {
        return value.replace(/[^a-z ]/gi, '');
    }

    const onChangeHandler = (fieldName, value) => {
        if (fieldName === "fullName") {
            setFullName(sanitizeText(value));
        } else if (fieldName === "emailAddress") {
            setEmailAddress(value);
        } else if (fieldName === "message") {
            setMessage(value);
        } else if (fieldName === "totalValue") {
            setTotalValue(value);
        }
    }

    const contactSubmit = async (e) => {
        e.preventDefault();
        if (totalValue == total){
            // right, send to the endpoint email
            let newObj = omit(errors, "totalValue");
            setErrors(newObj)

            const payload = {
                name: fullName,
                emailAddress: emailAddress,
                message: message,
                datasetId: datasetId,
                steward: dataSteward,
            };

            try {
                const response = await sendContributorEmail(payload);
                setMessages({
                    ...messages,
                    message: response.data.message
                })
                clearForm()
            } catch (error) {
                return;
            }
        } else {
            // alert message and create new createSum
            setTotalValue("")
            setErrors({
                ...errors,
                totalValue: 'Incorrect. Please try again.'
            })
            createSum()
            setTotalValue("")
        }
    }

    const getRandom = () => {
        return Math.ceil(Math.random() * 20)
    }

    const createSum = () => {
        let random1 = getRandom()
        let random2 = getRandom()
        total = random1 + random2
        setMathValue(random1 + " + " + random2 + " = ")
    }

    const clearForm = () => {
        setFullName("")
        setEmailAddress("")
        setMessage("")
        setTotalValue("")
        createSum()
    }

    return <>
        <div className="col-span-8 md:col-span-4 grid gap-y-4 mx-8 md:mx-0">
            <h4>Contact the Contributor</h4>
            <p>
                Use the form to ask a question or provide comments about this dataset to the contributor.
            </p>
            <div className="">
                <div className="p-8 bg-neutral-95 bg-opacity-30 rounded-3xl">
                    <form onSubmit={contactSubmit}>
                        <fieldset>
                            {
                                messages.message && <div className="bg-green-100 rounded-lg py-5 px-6 mb-4 text-base text-green-700 mb-3" role="alert">{messages.message}</div>
                            }
                            <input
                                className="h-14 rounded-full w-full px-8 gray_shadow mb-4"
                                id="fullName"
                                type="text"
                                placeholder={t('contact.name')}
                                value={fullName}
                                onChange={(e) => {
                                    onChangeHandler("fullName", e.target.value)
                                }}
                                required
                                autoFocus
                            />

                            <input
                                className="h-14 rounded-full w-full px-8 gray_shadow mb-4"
                                id="emailAddress"
                                type="email"
                                value={emailAddress}
                                onChange={(e) => {
                                    onChangeHandler("emailAddress", e.target.value)
                                }}
                                required
                                placeholder={t('contact.email')}
                            />

                            <textarea
                                className="h-32 rounded-3xl w-full px-8 gray_shadow mb-4"
                                id="message"
                                placeholder={t('contact.message')}
                                cols="60"
                                rows="5"
                                value={message}
                                required
                                onChange={(e) => {
                                    onChangeHandler("message", e.target.value)
                                }}
                            />

                            <div>
                                <label
                                    className="sm:text-sm justify-center w-full"
                                >
                                    {t('contact.captcha')}
                                    <br/>
                                    <strong>{t('contact.math_question')}</strong>
                                    <br/>
                                    {mathValue}
                                </label>
                            </div>
                            <input
                                className={errors.totalValue ? "h-14 rounded-full w-full px-8 gray_shadow mb-4 mt-2 border border-red-500" : "h-14 rounded-full w-full px-8 gray_shadow mb-4 mt-2"}
                                id="totalValue"
                                type="number"
                                value={totalValue}
                                onChange={(e) => {
                                    onChangeHandler("totalValue", e.target.value)
                                }}
                                required
                            />
                            {
                                errors.totalValue && <div className="bg-red-100 rounded-lg py-5 px-6 mb-4 text-base text-red-700 mb-3" role="alert">{errors.totalValue}</div>
                            }

                        </fieldset>

                        <button
                            className="btn_lg uppercase whitespace-nowrap bg-un-blue text-white hover:bg-sapphire-blue transition-colors"
                            type="submit">
                            {t('contact.submit')}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </>
}