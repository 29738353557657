import { useRef } from "react";
import ReactTooltip from "react-tooltip";

import { downloadDataset } from "../../shared/utils/fetchapi";

export default function DownloadComponent({
  id,
  isLoggedIn,
  accessToken,
  accessType,
}) {
  const idRef = useRef(null);

  const handleClick = (id, format) => {
    const loggedIn = isLoggedIn ? "internal" : "public";
    navigator.clipboard
      .writeText(`https://api.psdata.un.org/${loggedIn}/data/${id}/${format}`)
      .then(() => {
        console.log("Text copied to clipboard...");
      })
      .catch((err) => {
        console.log("Something went wrong", err);
      });
  };
  return (
    <div className="grid grid-cols-3 space-y-8 pr-8">
      <div className="col-span-3">
        {/* <div className="inline-block pb-10 border-b border-un-blue"><h4 className="font-bold">About this dataset</h4></div> */}
        <h4 className="text-xl font-bold">Formats available</h4>
      </div>

      <div className="col-span-3 md:col-span-1 flex flex-col items-center p-8 space-y-2">
        <a
          className="btn_sm text-white bg-un-blue  hover:bg-sapphire-blue transition-colors cursor-pointer"
          onClick={() => downloadDataset(id, "csv", accessType, accessToken)}
        >
          Download CSV
        </a>
        <div
          data-tip
          data-for="csv_copy"
          onClick={() => {
            handleClick(id, "csv");
          }}
        >
          <button className="btn_sm text-white bg-un-blue  hover:bg-sapphire-blue transition-colors cursor-pointer">
            Copy CSV link
          </button>
          <ReactTooltip
            type="success"
            id="csv_copy"
            event={"click"}
            isCapture={true}
            globalEventOff={"click"}
          >
            <span>Copied!</span>
          </ReactTooltip>
        </div>
      </div>

      <div className="col-span-3 md:col-span-1 flex flex-col items-center p-8 space-y-2">
        <a
          className="btn_sm text-white bg-un-blue  hover:bg-sapphire-blue transition-colors cursor-pointer"
          onClick={() => downloadDataset(id, "json", accessType, accessToken)}
        >
          Download JSON
        </a>
        {/* <a data-tip='Copied!'>( •̀д•́)</a> */}
        {/* <ReactTooltip isCapture={true} event="click" type="success" globalEventOff='click' /> */}

        <div
          data-tip
          data-for="json_copy"
          onClick={() => {
            handleClick(id, "json");
          }}
        >
          <button className="btn_sm text-white bg-un-blue  hover:bg-sapphire-blue transition-colors cursor-pointer">
            Copy JSON link
          </button>
          <ReactTooltip
            type="success"
            id="json_copy"
            event={"click"}
            isCapture={true}
            globalEventOff={"click"}
          >
            <span>Copied!</span>
          </ReactTooltip>
        </div>

        <div id="download-progress" className="mt-10"></div>
      </div>

      <div className="col-span-3 md:col-span-1 flex flex-col items-center p-8 space-y-2">
        <a
          className="btn_sm text-white bg-un-blue  hover:bg-sapphire-blue transition-colors cursor-pointer"
          onClick={() => downloadDataset(id, "xml", accessType, accessToken)}
        >
          Download XML
        </a>

        <div
          data-tip
          data-for="xml_copy"
          onClick={() => {
            handleClick(id, "xml");
          }}
        >
          <button className="btn_sm text-white bg-un-blue  hover:bg-sapphire-blue transition-colors cursor-pointer">
            Copy XML link
          </button>
          <ReactTooltip
            type="success"
            id="xml_copy"
            event={"click"}
            isCapture={true}
            globalEventOff={"click"}
          >
            <span>Copied!</span>
          </ReactTooltip>
        </div>
      </div>
      <div className="col-span-3 h-20"></div>

      <div className="hidden col-span-3 mt-20 h-32 bg-sapphire-blue md:flex flex-col justify-center rounded-3xl">
        <div className="flex flex-row justify-between items-center px-20">
          <p className="text-xl text-white">
            Looking to connect the data directly to your reports?
          </p>
          <a href="/howto">
            <span style={{ whiteSpace: "nowrap" }}>
              <button className="btn_md bg-white hover:bg-persian-indigo hover:text-white transition-opacity uppercase">
                See how
              </button>
            </span>
          </a>
        </div>
      </div>

      <div className="col-span-3 h-36"></div>
    </div>
  );
}
