import {Fragment} from 'react'
import {Disclosure, Menu, Transition} from '@headlessui/react'
import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function MenuBar() {

    const {t} = useTranslation();

    const navigation = [
        {name: 'footer.find_dataset', href: '/search', current: false, key: 'nav_search'},
        {name: 'footer.browse_by_theme', href: '/themes', current: false, key: 'nav_themes'},
        {name: 'footer.how_to', href: '/howto', current: false, key: 'nav_howto'},
        {name: 'footer.data_stories', href: '/stories', current: false, key: 'nav_stories'},
        {name: 'home.additional_resources', href: '/resources', current: false, key: 'nav_resources'},
        {name: 'footer.about', href: '/about', current: false, key: 'nav_about'},
    ]

    return (
        <div className="container mx-auto" style={{ zIndex: 50 }}>
            <div className="mx-auto mt-24 overflow-hidden bg-menu-background" >
                <div className="mx-auto max-w-7xl lg:divide-y lg:divide-gray-200">
                    <nav className="hidden lg:flex divide-x" aria-label="Global">
                        {navigation.map((item) => (
                            <NavLink
                                onClick={(e) => e.currentTarget.blur()}
                                role="button"
                                exact
                                key={item.key}
                                to={item.href}
                                style={{ whiteSpace: "nowrap" }}
                                activeClassName="border-b-4 border-un-blue bg-menu-hover text-gray-800"
                                className={classNames(
                                    item.current ? 'bg-menu-background text-gray-800' : 'text-gray-300 hover:bg-menu-hover hover:text-gray-800',
                                    'inline-flex items-center py-2 px-3 text-sm font-medium'
                                )}
                            >
                                {t(item.name)}
                            </NavLink>
                        ))}
                        <a></a>
                    </nav>
                </div>
            </div>
        </div>
    )
}