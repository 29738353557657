import React from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import {faker} from "@faker-js/faker";
import Slider from "./Slider/RangeSlider";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
    },
};

const labels = ['Jan', 'February', 'March', 'April', 'May', 'June', 'July'];

export const data = {
    labels,
    datasets: [
        {
            label: 'Views',
            data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
            backgroundColor: '#1990FE',
        },
        {
            label: 'Downloads',
            data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
            backgroundColor: '#0131DA',
        },
    ],
};

function LoadData ({analytics}) {
    const labels = analytics.map((item) => item["Month"])
    const views = analytics.map((item) => item.Requests.map((request) => request.Pageview).reduce((a, b) => a + b, 0))
    const downloads = analytics.map((item) => item.Requests.map((request) => request.Downloads).reduce((a, b) => a + b, 0))


    const data = {
        labels,
        datasets: [
            {
                label: 'Views',
                data: views,
                backgroundColor: '#1990FE',
            },
            {
                label: 'Downloads',
                data: downloads,
                backgroundColor: '#0131DA',
            },
        ],
    }

    return (
        <Bar options={options} data={data} />
    )
}

export function BarChart({title, analytics}) {
    return <>
        <div className="md:col-span-8 col-span-12 mb-8">
            <h4 className="font-bold">{title}</h4>
        </div>
        <LoadData analytics={analytics} />
    </>;
}