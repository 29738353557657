import './style.css'

export default function Metadata({metadata}) {
    return (
        <div className="space-y-8">
            <div className="md:col-span-8 col-span-12 unorg-home-hero">
                {/* <div className="inline-block pb-10 border-b border-un-blue"><h4 className="font-bold">About this dataset</h4></div> */}
                <h2>{metadata["Name"]}</h2>
            </div>
            {metadata["Description"] ? (
                <div>
                    <h4 className="pb-2">Description</h4>
                    {metadata["Description"].split('\n').map(str => <p className="py-1">{str}</p>)}
                </div>
            ) : (
                <></>
            )}
            {metadata["Update Frequency"] ? (
                <div>
                    <h4 className="pb-2">Update Frequency</h4>
                    <p>{metadata["Update Frequency"]} </p>
                </div>
            ) : (
                <></>
            )}
            {metadata["Organization"] ? (
                <div>
                    <h4 className="pb-2">Organization</h4>
                    <p>{metadata["Organization"]} </p>
                </div>
            ) : (
                <></>
            )}
            {metadata["Originating Offices"] ? (
                <div>
                    <h4 className="pb-2">Originating Office</h4>
                    <p>{metadata["Originating Offices"][0]["Originating Offices"]} </p>
                </div>
            ) : (
                <></>
            )}
            {metadata["Start Range"] ? (
                <div>
                    <h4 className="pb-2">Start Date of Dataset</h4>
                    <p>{metadata["Start Range"]} </p>
                </div>
            ) : (
                <></>
            )}
            {metadata["End Range"] ? (
                <div>
                    <h4 className="pb-2">End Date of Dataset</h4>
                    <p>{metadata["End Range"]} </p>
                </div>
            ) : (
                <></>
            )}
            {metadata["Limitations and Exceptions"] ? (
                <div>
                  <h4 className="pb-2">Limitations and Exceptions</h4>
                    {metadata["Limitations and Exceptions"].split('\n').map(str => <p className="py-1">{str}</p>)}
                </div>
            ) : (
                <></>
            )}
            {metadata["Themes"] ? (
                <div>
                    <h4 className="pb-2">Themes</h4>
                    <p>
                        {metadata["Themes"].map((i, k) => (
                            <span className="btn_sm" key={k}>
                {i.Theme}
              </span>
                        ))}
                    </p>
                </div>
            ) : (
                <></>
            )}
            {metadata["Formats"] ? (
                <div>
                    <h4 className="pb-2">Formats</h4>
                    <p className="flex flex-wrap">
                        {metadata["Formats"].map((i, k) => (
                            <span className="btn_sm" key={k}>
                {i.Format}
              </span>
                        ))}
                    </p>
                </div>
            ) : (
                <></>
            )}
            {metadata["Disaggregated by"] ? (
                <div>
                    <h4 className="pb-2">Disaggregated by</h4>
                    <p className="flex flex-wrap">
                        {metadata["Disaggregated by"].map((i, k) => (
                            <span className="btn_sm" key={k}>
                {i["Disaggregated by"]}
              </span>
                        ))}
                    </p>
                </div>
            ) : (
                <></>
            )}
            {metadata["Languages"] ? (
                <div>
                    <h4 className="pb-2">Languages Supported</h4>
                    <p className="flex flex-wrap">
                        {metadata["Languages"].map((i, k) => (
                            <span className="btn_sm" key={k}>
                {i.Language}
              </span>
                        ))}
                    </p>
                </div>
            ) : (
                <></>
            )}
            {metadata["Regions"] ? (
                <div>
                    <h4 className="pb-2">Regions Covered</h4>
                    <p className="flex flex-wrap">
                        {metadata["Regions"].map((i, k) => (
                            <span className="btn_sm" key={k}>
                {i.Region}
              </span>
                        ))}
                    </p>
                </div>
            ) : (
                <></>
            )}
            {metadata["Countries"] ? (
                <>
                    <span className="pb-2">Countries Covered</span>
                    <p className="flex flex-wrap">
                        {metadata["Countries"].map((i, k) => (
                            <span className="btn_sm" key={k}>
                {i.Country}
              </span>
                        ))}
                    </p>
                </>
            ) : (
                <></>
            )}
            {metadata["Missions"] ? (
                <div>
                    <h4 className="pb-2">Missions Covered</h4>
                    <p className="flex flex-wrap">
                        {metadata["Missions"].map((i, k) => (
                            <span className="btn_sm" key={k}>
                {i.Mission}
              </span>
                        ))}
                    </p>
                </div>
            ) : (
                <></>
            )}
        </div>
    );
}
