import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

export default function Story({ name, image, url, language }) {
    const {t} = useTranslation();
    const [foundKey, setFoundKey] = useState(null);
    const [lang, setLang] = useState(null);

    const translations = {
        "blogTitles": {
            "introducing": "Introducing the Peace and Security Data Hub",
            "quick_impact_projects": "What Are Quick Impact Projects",
            "measuring_peacebuilding": "Measuring Peacebuilding Funding",
            "fatalities": "How We Use The UN Peacekeeper Fatalities Dataset",
            "behind_numbers": "Behind the Numbers: Data and stories on women in peacemaking",
            "oda_peacebuilding": "ODA and Peacebuilding",
            "language_of_peace": "Language of Peace Database",
            "howto_data_story": "How to Tell a Data Story – Transform Data to Actionable Insights",
            "scads_data": "SCAD’s data, a key to better understanding the work of the Security Council",
            "wps_agenda": "Quantifying process in the implementation of the Women, Peace and Security agenda in peace operations through text analysis",
            "pema_story": "Peacekeeping Mandates (PEMA) Dataset",
            "peace_datahub_story": "Predictive modeling with peacekeeping demographic data",
            "hdx": "UN Peace and Security Data on HDX"
        }
    };

    function findKeyByValue(obj, value) {
        return Object.keys(obj).find(key => obj[key] === value);
    }

    useEffect(() => {
        const key = findKeyByValue(translations.blogTitles, name);
        setLang(language)
        setFoundKey('blogs.' + key);
    }, [translations.blogTitles, name, language]);

  return (
    <a href={url + '?lang=' + language} className="hover:underline">
      <div className="flex flex-col space-y-4 items-center justify-center " style={{width:280}}>
        <div className="" style={{width:280, height:280}} >
          <img src={image} className="rounded-2xl object-cover w-full h-full" alt={name} />
        </div>

      <div className="flex justify-between w-full items-center">
        <span className="uppercase text-sm">{foundKey ? t(foundKey) : ''}</span>
        <span>
          <svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 0.5L6 6.125L1 11.75" stroke="#000000"></path></svg>
        </span>
      </div>
      </div>
    </a>
  );
}
