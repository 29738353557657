import { useState, useEffect } from 'react';

import Virtualized from '../Table/Virtualized'

export default function Preview ({dataset, definitions}) {

  const [selectedDefinition, setSelectedDefinition] = useState(null)
  const [aggregatedDefinitions, setAggregatedDefinitions] = useState(null)
  const [attributes, setAttributes] = useState([])

  useEffect(()=>{
    setAggregatedDefinitions(definitions)
    if(definitions===null){return}
    definitions.map((definition)=>{
      if (definition.Attributes !== null){
        definition.Attributes.map((attr)=>{
          const newAttribute = {
            "Column Name": attr.Attribute,
            "Definition": attr.Definition,
            "Format": "n/a"

          }
          setAttributes(prev => [...prev, newAttribute])
        })
      }
    })
 
  }, [])


  const handleSelectChange = (e) => {
    e.value = JSON.stringify({"column_name": e.target.value["term"], "definition":e.target.value["Definition"], "format":e.target.value["Format"]})
    let objectifiedOption = JSON.parse(e.target.value)
    setSelectedDefinition(objectifiedOption)
  }

  const DefinitionsComponent = () =>{

    return (
      <>
      <div className="flex flex-row space-x-6 items-center mb-6">
        <label htmlFor="definition" className="block font-bold" style={{whiteSpace:"nowrap"}}>Search definitions for</label>
        <select onChange={e => handleSelectChange(e)} value={"Select an attribute"} id="definition" name="location" className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
          <option disabled="disabled" defaultValue="Select an attribute" hidden="hidden">Select an attribute</option>
          <optgroup label="Column Definition">
          {aggregatedDefinitions &&
            aggregatedDefinitions.map((def, key)=>{
              return (
                <option key={key} value={JSON.stringify({"term": def["Column Name"], "definition":def["Definition"], "format":def["Format"]})}>{def["Column Name"]}</option>
              )
            })
          }
          </optgroup>
          {attributes.length !== 0 &&

            <optgroup label="Attribute Definition">
              {attributes.map((def, key)=>{
                return (
                  <option key={key} value={JSON.stringify({"term": def["Column Name"], "definition":def["Definition"], "format":def["Format"]})}>{def["Column Name"]}</option>
                )
              })
            }
            </optgroup>
          }
        </select>
      </div>
      { selectedDefinition ?
      <div className="grid grid-cols-9 gap-x-6 mb-12 gap-y-6 border-l border-neutral-30 pl-8">
        <div className="col-span-2">
          Term:
        </div>
        <div className="col-span-7">
          {selectedDefinition.term}
        </div>
        <div className="col-span-2">
          Definition:
        </div>
        <div className="col-span-7">
          {selectedDefinition.definition}
        </div>
        <div className="col-span-2">
          Type:
        </div>
        <div className="col-span-7">
          {selectedDefinition.format}
        </div>
      </div> : <></>
      }

    </>
  
    )}


  return (
    <>
    <div className="w-full rounded-3xl bg-white p-8 gray_shadow mb-6" >
      <div className="overflow-scroll bg-white overflow-y-hidden">
        {dataset !== null && (
          <Virtualized  dataset={dataset}/>
        )}
        {dataset === null && (
          <>There was a problem fetching data</>)
        }
      </div>
      <p className="text-sm mt-8">Showing {dataset.length >= 500 ? "first" : ""} {dataset.length} rows.</p>
    </div>
    {
      aggregatedDefinitions &&
      <DefinitionsComponent />
    }
    </>
  )
}

