
import {Link} from'react-router-dom';
import Looking from '../../assets/img/stock/looking.png';
import GreenSpiral from '../../assets/img/GreenSpiral.svg';
import {useTranslation} from "react-i18next";

export const CantFind = () => {

  const {t} = useTranslation();

  return (
    <div className="container mx-auto mb-20 -mt-20 relative" >
      <div className="grid grid-cols-12 h-full pt-20 mx-6 gap-x-6">
        <div className="col-span-12 md:col-span-5 md:col-start-2 h-full flex flex-col justify-center space-y-8">
          <div className="flex flex-col space-y-10 unorg-home-hero" >
            <h2>{t('home.not_sure_looking')}</h2>
            <div className="grid grid-cols-5 gap-x-6">
              <p className="col-span-4 text-md md:text-2xl text-roboto font-light">{t('home.try_search')}</p>
            </div>
            <div className="uppercase flex space-x-4">
              <Link to="/themes" className="transition-opacity text-sm font-bold button_secondary"><span>{t('home.explore_data_themes')}</span></Link>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="12" fill="#80A8D0"/><path d="M9.5 14.9401V9.05991C9.5 8.62843 9.91802 8.35959 10.2509 8.577L14.7523 11.5171C15.0826 11.7328 15.0826 12.2672 14.7523 12.4829L10.2509 15.423C9.91802 15.6404 9.5 15.3716 9.5 14.9401Z" fill="white"/></svg>
            </div>
          </div>
        </div>
        <div className="col-span-4 col-start-8 h-full hidden md:flex flex-col justify-center">
          <div style={{height: 520}} className="relative">
            <div className="absolute" style={{width:96, borderRadius: 15, height:96, top:64, left:-48, background: "linear-gradient(0deg, rgba(251,225,28,0.43039222524947474) 0%, rgba(255,205,198,1) 100%)"}}></div>
            <img className="absolute" style={{zIndex: "-1", bottom: "-95px", right: "-75px"}} src={GreenSpiral} alt=""/>
            <div className="flex items-center justify-center overflow-hidden h-full" style={{borderRadius:45}}>
              <img className="" style={{width: "100%",  height: "100%", objectFit:"cover"}} src={Looking} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}