import {useState, useEffect} from 'react';
import './styles/resources.css'

import StockImg from '../assets/img/stock/themes.jpg';

import {fetchThemes} from '../shared/utils/fetchapi.js';
import {useTranslation} from "react-i18next";

const CACHE = {}

export default function DataThemes() {

    const [themes, setThemes] = useState([]);
    const {t, i18n} = useTranslation()
    document.body.dir = i18n.dir()

    let getThemes = async () => {
        if (CACHE.themes !== undefined) {
            setThemes(CACHE.themes)
            return
        }

        const response = await fetchThemes();

        CACHE.themes = response.data
        setThemes(response.data);
    }


    useEffect(() => {
        getThemes();

    }, []);


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className="container relative mx-auto header grid grid-cols-12 gap-x-6 px-6 overflow-hidden mt-auto"
                 style={{"height": "300px"}}>
                <div
                    className="col-span-12 md:col-span-4 col-start-2 flex flex-col mt-10 space-y-8 unorg-home-hero">
                    <h1 className="font-bold">{t('themes.title')}</h1>
                    <p>{t('themes.slogan')}</p>
                </div>
                <div className="hidden md:block col-span-4 col-start-8 relative">
                    <div className="right-0 -bottom-10 h-5/6 overflow-hidden left-0 mt-20">
                        <div className="h-full">
                            <img src={StockImg} alt=""
                                 style={{borderRadius: 45, objectFit: "cover", width: "100%", height: "100%"}}/>
                        </div>
                    </div>
                </div>
            </div>


            <div className="container mx-auto grid grid-cols-12 py-16 gap-x-6 col-start-3">
                <div className="grid grid-cols-9 col-span-10 col-start-2 gap-y-12">
                    {
                        themes.length > 0 ?
                            themes.map((i, key) => {
                                return (
                                    <a href={`/search?theme=${i.themes}`}
                                       className="flex flex-col col-span-9 md:col-span-3 justify-center items-center space-y-6 hover:opacity-70 transition-opacity"
                                       key={key}>
                                        <div className="w-32 justify-center flex">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="12" cy="12" r="12" fill="#80A8D0"/>
                                                <path
                                                    d="M9.5 14.9401V9.05991C9.5 8.62843 9.91802 8.35959 10.2509 8.577L14.7523 11.5171C15.0826 11.7328 15.0826 12.2672 14.7523 12.4829L10.2509 15.423C9.91802 15.6404 9.5 15.3716 9.5 14.9401Z"
                                                    fill="white"/>
                                            </svg>
                                        </div>
                                        <span>{i.themes}</span>
                                    </a>
                                )
                            }) : <div className="w-full h-full flex flex-col items-center col-span-12 justify-center">
                                <div className="lds-ripple">
                                <div></div>
                                    <div></div>
                                </div>
                            </div>
                    }

                </div>

            </div>


        </>

    )
}
