import React, {Fragment} from "react";
import i18n from "../../../i18n";
import {Menu, Transition} from "@headlessui/react";
import {ChevronDownIcon} from "@heroicons/react/solid";
import i18next, {t} from "i18next";

const languages = [
    {code: 'ar', name: 'عربي', status: 'success'},
    {code: 'ch', name: '中文', status: 'success'},
    {code: 'en', name: 'English', status: 'success'},
    {code: 'fr', name: 'Français', status: 'success'},
    {code: 'ru', name: 'Русский', status: 'success'},
    {code: 'es', name: 'Español', status: 'success'},
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

class LanguageSwitcherComponent extends React.Component {
    state = {
        lang: 'en'
    };

    changeLanguageHandler = (lang) => {
        this.setState({lang: lang});
        i18n.changeLanguage(lang)
    }

    render() {
        return (
            <div className="languageSwitcher">
                <DropdownLanguageSelector
                    lang={this.state.lang}
                    handleChangeLanguage={this.changeLanguageHandler}
                />
            </div>
        )
    }
}

class DropdownLanguageSelector extends React.Component {
    onChange = (e, code) => {
        this.props.handleChangeLanguage(code);
    }

    render() {
        const options = languages.map(language => {
            return <Menu.Item key={language.code}>
                {({active}) => (
                    <div>
                        <a
                            href="#"
                            className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 text-sm'
                            )}
                            onClick={event => this.onChange(event, language.code)}
                        >
                            {language.name}
                        </a>
                    </div>
                )}
            </Menu.Item>
        });

        return (
            <Menu as="div" className="relative inline-block text-left">
                <div>
                    <Menu.Button
                        className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                            {languages.filter(language => {
                                return language.code === this.props.lang
                            }).map(language => {
                                return language.name
                            })}
                        <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true"/>
                    </Menu.Button>
                </div>

                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items
                        className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="px-4 py-3">
                            <p className="text-sm">{t('navbar.select_language')}</p>
                        </div>
                        <div className="py-1">
                            {options}
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        )
    }
}

export default LanguageSwitcherComponent;
