import React, { useState, useEffect, useRef } from "react";

import { InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";

import Sidebar from "../components/Search/Sidebar.js";
import SearchResult from "../components/Search/SearchResult.js";
import CountryFilter from "../components/Search/CountryFilter.js";
import MissionFilter from "../components/Search/MissionFilter.js";
import Fuse from "fuse.js";

import BackgroundImage from "../assets/img/NoResult.png";

import ScrollToTopBtn from "../components/Global/ScrollTop";

import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";

import {
  fetchMetadataInternal,
  fetchMetadata,
} from "../shared/utils/fetchapi.js";

import CloseButton from "../assets/x_close.svg";

const CACHE = {};

export default function SearchContainer(props) {
  const { instance, inProgress } = useMsal();

  /************************
   * MAIN SIDEBAR FILTER  *
   ************************/
  const [fullList, setFullList] = useState([]);

  const [query, updateQuery] = useState([]);
  const [queryTerms, updateQueryTerms] = useState([]);
  const [sortQueryResults, setSortQueryResults] = useState(null);

  const [themeQuery, setThemeQuery] = useState([]);

  const [cQuery, setCQuery] = useState([]);
  const [flattenedCountryQuery, setFlattenedCountryQuery] = useState([]);

  const [showCountryFilter, setShowCountryFilter] = useState(false);
  const [showMissionFilter, setShowMissionFilter] = useState(false);
  const [missionQuery, setMissionQuery] = useState([]);

  const [typeFilter, setTypeFilter] = useState(null);

  const [showSearch, setShowSearch] = useState(true);

  const filteredList = useRef(null);

  var searchOpts = {
    shouldSort: true,
    threshold: 0.3,
    findAllMatches: true,
    ignoreLocation: true,
    minMatchCharLength: 3,
    matchAllTokens: true,
    tokenize: true,
    includeScore: true,
    keys: [
      {
        name: "Name",
        weight: 0.2,
      },
      {
        name: "Description",
        weight: 0.2,
      },
      {
        name: "Themes.theme",
        weight: 0.1,
      },
      {
        name: "Missions.Mission",
        weight: 0.1,
      },
      {
        name: "Countries.Country",
        weight: 0.3,
      },
      {
        name: "all",
        weight: 0.3,
      },
    ],
  };

  // FLATTENS COUNTRY, MISSIONS, THEMES LIST FOR
  // SEARCH WITH FUSE.IO
  function combineAllKeyValues(obj, separator) {
    separator = separator || " ";
    let missions,
      countries,
      themes = "";

    if (obj.Missions !== null) {
      missions = obj.Missions.map((s) => s.Mission).join(separator);
    }

    if (obj.Countries !== null) {
      countries = obj.Countries.map((s) => s.Country).join(separator);
    }

    if (obj.Themes !== null) {
      themes = obj.Themes.map((s) => s.Theme).join(separator);
    }

    if (missions === undefined) {
      missions = "";
    }
    if (countries === undefined) {
      countries = "";
    }
    if (themes === undefined) {
      themes = "";
    }

    obj.all = `${missions} ${countries} ${themes}`;

    return obj;
  }

  // CREATES FUSE SEARCH

  const fuse = new Fuse(fullList, searchOpts);

  useEffect(() => {
    // setThemeQuery([''])
    setMissionQuery([""]);
  }, []);

  useEffect(() => {
    updateQueryTerms([
      ...flattenedCountryQuery,
      ...missionQuery,
      ...themeQuery,
    ]);
  }, [flattenedCountryQuery, missionQuery, themeQuery]);

  useEffect(() => {
    updateQuery(queryTerms.join(" "));
  }, [queryTerms]);

  const results = fuse.search(query);

  const checkTypeFilter = (typeFilter, results) => {
    if (typeFilter) {
      return results.filter((dataset) => dataset["Type"] === typeFilter);
    } else {
      return fullList;
    }
  };

  // Filter queries
  let queryResults =
    query !== " " && query !== ""
      ? results.map((r) => r.item)
      : checkTypeFilter(typeFilter, fullList);

  // useEffect(() => {
  //   console.log(results, 'results')
  //   if(queryResults !== sortQueryResults) {
  //    setSortQueryResults(queryResults);
  //   }

  // }, [results]);

  // useEffect(() => {
  //   console.log(queryResults, sortQueryResults, 'qr')
  //   if(queryResults !== sortQueryResults) {
  //     console.log(sortQueryResults, queryResults)
  //     // setSortQueryResults(queryResults);
  //     console.log("NOT THE SAMEK")
  //   } else if (queryResults === sortQueryResults){
  //     console.log("THESm")
  //   }
  // }, [queryResults]);

  const handleFilterSelection = (e) => {
    // SHOW FILTER SIDEBAR IF SELECTED
    switch (e) {
      case "country":
        setShowSearch(false);
        setShowMissionFilter(false);
        setShowCountryFilter(true);
        break;
      case "mission":
        setShowSearch(false);
        setShowCountryFilter(false);
        setShowMissionFilter(true);
        break;
      default:
        setShowSearch(true);
        setShowMissionFilter(false);
        setShowCountryFilter(false);
    }
  };

  /***********************
   *  END SIDEBAR FILTER  *
   ***********************/

  /***********************
   ***  COUNTRY FORMAT  ***
   ***********************/

  useEffect(() => {
    let flattenedCountries_ = [];
    // console.log(cQuery, "from container")
    for (let key of Object.keys(cQuery)) {
      if (cQuery[key].length > 0) {
        cQuery[key].forEach((c) => flattenedCountries_.push(c));
      }
    }
    setFlattenedCountryQuery(flattenedCountries_);
  }, [cQuery]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const searchbar = useRef(null);

  const [mainList, setMainList] = useState([]);

  const [mainQuery, updateMainQuery] = useState([]);
  const [subQueryList, updateSubQueryList] = useState([]);
  const [countryQuery, updateCountryQuery] = useState([]);
  const [clearedCountries, setClearedCountries] = useState({});

  /***********************************
   * FETCHES METADATA AND PASSES     *
   * METADATA COMPONENTS             *
   **********************************/
  useEffect(() => {
    const accessTokenRequest = {
      scopes: [
        process.env.REACT_APP_MSAL_API_SCOPE ||
          "https://api-psdatadev.un.org/api.call",
        "user.read",
      ],
    };
    if (inProgress === InteractionStatus.None) {
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
          // Acquire token silent success
          let accessToken = accessTokenResponse.accessToken;
          // Call your API with token
          delete CACHE.all;
          getMasterListInternal(accessToken);
        })
        .catch(() => {
          getMasterListPublic();
        });
    }
  }, [instance, inProgress]);

  let getMasterListInternal = async (accessToken) => {
    if (CACHE.all !== undefined) {
      setMainList(CACHE.all);
      setFullList(CACHE.full);
      return;
    }
    const response = await fetchMetadataInternal("all", accessToken);
    CACHE.all = response.data;
    setMainList(response.data);
    const full = response.data.map((s) => combineAllKeyValues(s));
    CACHE.full = full;
    setFullList(full);
  };

  let getMasterListPublic = async () => {
    if (CACHE.all !== undefined) {
      setMainList(CACHE.all);
      setFullList(CACHE.full);
      return;
    }
    const response = await fetchMetadata("all");
    CACHE.all = response.data;
    setMainList(response.data);
    const full = response.data.map((s) => combineAllKeyValues(s));
    CACHE.full = full;
    setFullList(full);
  };

  const handleClearSearch = () => {
    // CLEAR SEARCHBAR
    updateQuery(mainQuery.join(" "));
    updateSubQueryList([]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(queryTerms, "q")
    // searchinput.current.value=searchbar.current.value;
    // updateQueryTerms([...queryTerms, searchbar.current.value])
    const searchTerm = searchbar.current.value;

    updateSubQueryList([...subQueryList, searchTerm]);
  };

  useEffect(() => {
    console.log(subQueryList, "SQL")

    const subQueries = subQueryList.join(" ");
    let update = mainQuery.join(" ") + " " + subQueries;
    updateQuery(update);

    console.log(query, "QUERY SQLKj")
  }, [subQueryList]);

  const SearchSection = () => {
    return (
      <>
        <div className="search-bar flex flex-row justify-between items-center">
          <div className="search-group w-full relative">
            <form onSubmit={handleSubmit}>
              <input
                ref={searchbar}
                className="h-14 rounded-full w-full px-8 gray_shadow"
                id="searchbar"
                type="text"
                placeholder="Search..."
              />
            </form>
            <div
              id="searchclear"
              className="absolute right-5 m-auto top-0 bottom-0 h-6 text-black cursor-pointer flex flex-col justify-center"
              onClick={handleClearSearch}
            >
              <img src={CloseButton} alt="" />
            </div>
          </div>
        </div>
      </>
    );
  };

  const handleClearCountries = () => {
    let randVal = Math.random();
    setClearedCountries(randVal);
  };

  /*
   * Sort Dropdown
   */
  const sort = {
    name: function () {
      console.log(queryResults, "name");
      let sorted = queryResults.sort((a, b) => {
        const nameA = a.Name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.Name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
      setSortQueryResults(sorted);
    },
    updated: function () {
      console.log("update");
      let sorted = queryResults.sort((a, b) => {
        return (
          new Date(b["Last Update Date"]) - new Date(a["Last Update Date"])
        );
      });
      setSortQueryResults(sorted);
    },
    access: function () {
      console.log("access");
      let sorted = queryResults.sort((a, b) => {
        const nameA = a.Type.toUpperCase(); // ignore upper and lowercase
        const nameB = b.Type.toUpperCase(); // ignore upper and lowercase
        if (nameA > nameB) {
          return -1;
        }
        if (nameA < nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
      setSortQueryResults(sorted);
    },
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const SortComponent = (queryResults) => {
    return (
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
            Sort
            <ChevronDownIcon
              className="-mr-1 ml-2 h-5 w-5"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <a
                    href="#"
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )}
                    onClick={() => sort.name(sortQueryResults)}
                  >
                    Name
                  </a>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <a
                    href="#"
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )}
                    onClick={() => sort.updated(sortQueryResults)}
                  >
                    Last Updated Date
                  </a>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <a
                    href="#"
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )}
                    onClick={() => sort.access(sortQueryResults)}
                  >
                    Access Type
                  </a>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    );
  };

  return (
    <>
      <div className="container center grid min-h-screen m-auto grid-cols-12 mt-20 relative">
        <div className="col-span-3 h-full text-white md:block hidden">
          <div className="mt-16">
            <Sidebar
              setThemeQuery={setThemeQuery}
              themeQuery={themeQuery}
              setShowCountryFilter={setShowCountryFilter}
              showCountryFilter={showCountryFilter}
              clearCountries={handleClearCountries}
              setShowMissionFilter={setShowMissionFilter}
              handleFilterSelection={handleFilterSelection}
              missionQuery={missionQuery}
              cQuery={cQuery}
              setMissionQuery={setMissionQuery}
              setCQuery={setCQuery}
              query={query}
              setTypeFilter={setTypeFilter}
              setSortQueryResults={setSortQueryResults}
            />
          </div>
        </div>

        {showMissionFilter ? (
          <MissionFilter
            missionQuery={missionQuery}
            setMissionQuery={setMissionQuery}
            setShowMissionFilter={setShowMissionFilter}
          />
        ) : (
          <></>
        )}

        {showCountryFilter ? (
          <CountryFilter
            setCQuery={setCQuery}
            countryQuery={countryQuery}
            clearedCountries={clearedCountries}
            setShowCountryFilter={setShowCountryFilter}
          />
        ) : (
          <></>
        )}

        {/*
         * MAIN CONTENT
         */}

        {!showCountryFilter && !showMissionFilter && mainList.length > 0 ? (
          <div className="md:col-span-9 md:col-start-4 col-span-12 bg-neutral-95 p-8 bg-opacity-30 space-y-8">
            <div className="flex space-x-4 items-center">
              <div className="grow w-full">
                <SearchSection />
              </div>
              <div className="grow-0">
                <SortComponent queryResults={queryResults} />
              </div>
            </div>
            { queryResults ? (
              <div>
                {queryResults.length} datasets found.
              </div>

            ) : <></>

            }
            <SearchResultContainer queryResults={sortQueryResults && sortQueryResults.length > 0 ? sortQueryResults : queryResults} />
          </div>
        ) : (
          <></>
        )}
      </div>

      <div className="grid grid-cols-12 sticky bottom-10">
        <ScrollToTopBtn />
      </div>
    </>
  );
}

const SearchResultContainer = (props) => {
  if (props.queryResults.length) {
    return props.queryResults.map((item, key) => {
      return <SearchResult metadata={item} key={key} />;
    });
  } else {
    return <NoResults />;
  }
};

const NoResults = () => (
  <div className="flex flex-col justify-center items-center text-sapphire-blue space-y-6">
    <p>No results match your search term.</p>
    <p>Try rephrasing your term. We will search for you again!</p>
    <img src={BackgroundImage} alt="" />
  </div>
);
