export const PublicAccess = ({setTypeFilter}) => {
  const handleClick = (e) => {
      e.currentTarget.blur()
      setTypeFilter("Publicly Released")
    }
  return (
    <button onClick={handleClick} className="bg-dark-cyan px-2 rounded"> 
      <span className="text-white font-bold">Public</span>
    </button>
  )
}

export const RestrictedAccess = ({setTypeFilter}) => {
  const handleClick = (e) => {
      e.currentTarget.blur()
      setTypeFilter("Restricted")
    }
  return (
    <button onClick={handleClick} className="bg-un-blue px-2 rounded"> 
      <span className="text-white font-bold">Internal</span>
    </button>
  )
}

export const AllAccess = ({setTypeFilter}) => {
  const handleClick = (e) => {
      e.currentTarget.blur()
      setTypeFilter(null)
    }
  return (
    <button onClick={handleClick} className="bg-neutral-76 px-2 rounded"> 
      <span className="text-white font-bold">All</span>
    </button>
  )
}