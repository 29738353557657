import { Link } from 'react-router-dom';
import LinkButton from '../Global/LinkButton';
import Exclamation from '../../assets/Exclamation.svg';
import Arrow from '../../assets/backArrow.svg';
import email from '../../assets/email.svg';
import copylink from '../../assets/copylink.svg';
import externalicon from '../../assets/external_icon.svg';
import { useState, useEffect } from 'react';

export default function Sidebar({metadata}) {

  const [tags, setTags] = useState([])

  useEffect(() => {
    if (metadata["Tags"]=== undefined){
      return
    } else {
      const TagList = []
      if(metadata["Themes"]!==null){
        metadata["Themes"].forEach((theme)=>{
          TagList.push(theme.Theme)
        })
      }
      setTags(TagList)
      return
    }
  }, [metadata])
  // const tags = metadata.tags.split(',');
  // console.log(metadata.tags)

  const copyTextToClipboard = (text) => {
    var textArea = document.createElement("textarea");

    // Place in top-left corner of screen regardless of scroll position.
    textArea.style.position = 'fixed';
    textArea.style.top = 0;
    textArea.style.left = 0;

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = '2em';
    textArea.style.height = '2em';

    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = 0;

    // Clean up any borders.
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';

    // Avoid flash of white box if rendered for any reason.
    textArea.style.background = 'transparent';

    textArea.value = text;

    document.body.appendChild(textArea);

    textArea.select();

    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
    } catch (err) {
      console.log('Oops, unable to copy');
    }

    document.body.removeChild(textArea);
  }

  const copyLink = () => {
    copyTextToClipboard(document.location.href);
  }



  return (

    <div className="rounded-tr-2xl text-black">
      <div className="flex flex-col space-y-4">
      <h4 className="font-bold leading-tight text-sapphire-blue">{metadata["Name"] ? metadata["Name"] : "loading"}</h4>
        <div className="flex flex-wrap">
          <span className="font-bold w-24">Dataset ID</span>
          <p>{metadata["Dataset ID"] ? metadata["Dataset ID"] : "loading"}</p>
        </div>
        <div className="flex">
          <span className="font-bold w-24">Issued by</span>
          <p>{metadata["Organization"] ? metadata["Organization"] : "loading"}</p>
        </div>
        {metadata["Last Update Date"] ?
          <div className="flex">
            <span className="font-bold w-24">Updated</span>
            <p>{metadata["Last Update Date"] ? metadata["Last Update Date"] : ""}</p>
          </div>
        : <></>}
        {metadata["Limitations and Exceptions"] ?
          <div className="flex">
              <img alt="" className="mr-4 self-start mt-2" src={Exclamation} alt="Warning symbol" />
              <p className="text-sm line-clamp-3"> {metadata["Limitations and Exceptions"]}</p>
          </div>:<></>
        }

        {metadata["Relevant Website"] ? <a className="font-bold text-sapphire-blue" href={`${metadata["Relevant Website"]}`} target="_blank">Relevant Website  <img className="ml-4 inline" src={externalicon} /></a>:""}


        {/* <p><span className="font-bold">Last updated on </span>{metadata.release}</p>  */}
        {metadata["Themes"]  ? (
            <div>
              <hr className="border-neutral-76" />
              <p><span className="font-bold mr-8">Themes</span>{tags!== [] ? tags.map((theme, key)=><LinkButton to={`/search?theme=${theme}`} key={key} className="btn_sm border-sapphire-blue text-sapphire-blue border m-1">{theme}</LinkButton>) : "loading"}</p></div>
        ) : (
            <></>
        )}
        <hr className="border-neutral-76" />
        <p><span className="font-bold mr-8">Share</span></p>
        <div className="inline-flex space-x-2">
          <img src={copylink} alt="copy link" className="hover:opacity-80 active:opacity-100" role="button" onClick={copyLink} />
          <a href={`mailto:?subject=${metadata["Name"]} - Peace%20and%20Security%20Datahub&body=${metadata["Name"]} - https://psdata.un.org/dataset/${metadata["Dataset ID"]}`}><img src={email} className="hover:opacity-80 active:opacity-100" alt="email link"/></a>
        </div>
        <hr className="border-neutral-76" />
        <Link to="/search" className="text-xs text-mono uppercase flex items-center"><img alt="" className="inline mr-8" src={Arrow} /> Back to search</Link>
      </div>
    </div>

  )
}