import BackgroundImage from "../../assets/img/NoResult.png";

const NoResults = () => {
  return(

  <div className="flex flex-col justify-center items-center text-sapphire-blue space-y-6">
    <p>No results match your search term.</p>
    <p>Try rephrasing your term. We will search for you again!</p>
    <img src={BackgroundImage} alt="" />
  </div>
  )
}
;

export default NoResults;