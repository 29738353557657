import SearchResult from "./SearchResult.js";
import BackgroundImage from "../../assets/img/NoResult.png";

const SearchResultContainer = ({ fetchedData, metadata }) => {
  if (!fetchedData) return "Loading datasets";

  return (
    <div className="space-y-4">
      {metadata ? (
        metadata.map((item, key) => {
          return <SearchResult metadata={item} key={key} />;
        })
      ) : (
        <NoResults />
      )}
    </div>
  );
};

const NoResults = () => (
  <div className="flex flex-col justify-center items-center text-sapphire-blue space-y-6">
    <p>No results match your search term.</p>
    <p>Try rephrasing your term. We will search for you again!</p>
    <img src={BackgroundImage} alt="" />
  </div>
);

export { SearchResultContainer };
