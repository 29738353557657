import {Link}from 'react-router-dom';

import ScrollToTopBtn from "../components/Global/ScrollTop";


import {useEffect} from 'react';
import './styles/HowTo.css'

import xml1 from '../assets/img/howto/xml1.png';
import xml2 from '../assets/img/howto/xml2.png';
import xml3 from '../assets/img/howto/xml3.png';
import xml4 from '../assets/img/howto/xml4.png';
import xml5 from '../assets/img/howto/xml5.png';
import xml6 from '../assets/img/howto/xml7.png';

import json from '../assets/img/howto/json.png';
import json2 from '../assets/img/howto/json2.png';
import json3 from '../assets/img/howto/json3.png';
import json4 from '../assets/img/howto/json4.png';
import json5 from '../assets/img/howto/json5.png';
import json6 from '../assets/img/howto/json6.png';
import json7 from '../assets/img/howto/json7.png';
import json8 from '../assets/img/howto/json8.png';

import xmlbi1 from '../assets/img/howto/XML_BI_1.png';
import xmlbi2 from '../assets/img/howto/XML_BI_2.png';
import xmlbi3 from '../assets/img/howto/XML_BI_3.png';
import xmlbi4 from '../assets/img/howto/XML_BI_4.png';
import xmlbi5 from '../assets/img/howto/XML_BI_5.png';

import jsonbi1 from '../assets/img/howto/JSON_BI_1.png';
import jsonbi2 from '../assets/img/howto/JSON_BI_2.png';
import jsonbi3 from '../assets/img/howto/JSON_BI_3.png';
import jsonbi4 from '../assets/img/howto/JSON_BI_4.png';
import jsonbi5 from '../assets/img/howto/JSON_BI_5.png';
import jsonbi6 from '../assets/img/howto/JSON_BI_6.png';
import jsonbi7 from '../assets/img/howto/JSON_BI_7.png';
import jsonbi8 from '../assets/img/howto/JSON_BI_8.png';
import {useTranslation} from "react-i18next";


export default function Instructions ({match}) {
  useEffect(()=>{
    window.scrollTo(0,0)
  })
  return (
    <>
    <div className="container my-32 px-6 mx-auto">
      {
        match.params.format === "excel_xml" ?
          <Xml/>  :
          match.params.format === "excel_json"  ?
          <Json /> :
          match.params.format === "powerbi_xml"  ?
          <PowerbiXML /> :
          match.params.format === "powerbi_json"  ?
          <PowerbiJSON /> :
          match.params.format === "api_structure"  ?
          <ApiStructure /> :

          "Please choose a different format"
      }
</div>



    </>

  )
}



const Xml = ()=>{

  const {t} = useTranslation()

  return (
    <>
    <div className="space-y-6">
      <div className="flex space-x-6">
        <span className="uppercase"><Link to="/howto" className="hover:underline hover:text-sapphire-blue">HowTo</Link></span>
        <span>&gt;</span>
        <span className="uppercase text-sapphire-blue underline">XML API TUTORIAL:EXCEL</span>
      </div>
      <h1 className="text-sapphire-blue font-bold">XML API Tutorial: Excel</h1>
      <p className="text-3xl font-light pt-12">{t('how_to.json_pull_data')}</p>
      <hr/>
    </div>
      <h2 className="text-2xl my-12">{t('how_to.json_import_data')}</h2>
      <div className="space-y-4">
        <p className="font-bold">1. {t('how_to.json_get_data_web')}</p>
        <p>{t('how_to.json_get_data_web1')}</p>
        <img src={xml1} alt="screenshot of excel"/>
      </div>

      <div className="space-y-4 mt-6">
        <p className="font-bold">2. {t('how_to.json_get_data_web2')}</p>
        <img src={xml2} alt="screenshot of excel"/>
      </div>

      <div className="space-y-4 mt-6">
        <p className="font-bold">3. {t('how_to.xml_get_data1')}</p>
        <p>{t('how_to.xml_get_data2')}</p>
        <img src={xml3} alt="screenshot of excel"/>
      </div>

      <div className="space-y-4 mt-6">
        <p className="font-bold">4. {t('how_to.json_get_data_web9')}</p>
        <p>{t('how_to.json_get_data_web10')}</p>
        <img src={xml4} alt="screenshot of excel"/>
      </div>

      <h2 className="text-2xl my-6">{t('how_to.xml_get_data3')}</h2>

      <div className="space-y-4 mt-6">
        <p className="font-bold">1. Refresh dataset</p>
        <p>{t('how_to.xml_get_data4')}</p>
        <img src={xml5} alt="screenshot of excel"/>
      </div>

      <div className="space-y-4 mt-6">
        <p className="font-bold">2. {t('how_to.xml_get_data5')}</p>
        <p>{t('how_to.xml_get_data6')}</p>
        <img src={xml6} alt="screenshot of excel"/>
      </div>

    </>
  )
}

const Json = ()=>{
  const {t} = useTranslation()

  return (
    <>
      <div className="space-y-6">
        <div className="flex space-x-6">
          <span className="uppercase"><Link to="/howto" className="hover:underline hover:text-sapphire-blue">HowTo</Link></span>
          <span>&gt;</span>
          <span className="uppercase text-sapphire-blue underline">JSON API TUTORIAL:EXCEL</span>
        </div>
        <h1 className="text-sapphire-blue font-bold">JSON API Tutorial: Excel</h1>
      <p className="text-3xl font-light pt-12">{t('how_to.json_pull_data')}</p>
      </div>
      <hr/>

      <h2 className="text-2xl my-12">{t('how_to.json_import_data')}</h2>

      <div className="space-y-4 mt-6">
        <p className="font-bold">1. {t('how_to.json_get_data_web')}</p>
        <p>{t('how_to.json_get_data_web1')}</p>
        <img src={xml1} alt="screenshot of excel"/>
      </div>

      <div className="space-y-4 mt-6">
        <p className="font-bold">2. {t('how_to.json_get_data_web2')}</p>
        <img src={json2} alt="screenshot of excel"/>
      </div>


      <div className="space-y-4 mt-6">
        <p className="font-bold">3. {t('how_to.json_get_data_web3')}</p>
        <p>3-1. {t('how_to.json_get_data_web4')}</p>
        <img src={json3} alt="screenshot of excel"/>
      </div>


      <div className="space-y-4 mt-6">
        <p>3-2. {t('how_to.json_get_data_web5')}</p>
        <img src={json4} alt="screenshot of excel"/>
      </div>

      <div className="space-y-4 mt-6">
        <p>3-3. {t('how_to.json_get_data_web6')}</p>
        <img src={json8} alt="screenshot of excel"/>
      </div>

      <div className="space-y-4 mt-6">
        <p>3-4. {t('how_to.json_get_data_web7')}</p>
        <img src={json} alt="screenshot of excel"/>
      </div>

      <div className="space-y-4 mt-6">
        <p>3-5. {t('how_to.json_get_data_web8')}</p>
        <img src={json5} alt="screenshot of excel"/>
      </div>

      <div className="space-y-4 mt-6">
        <p className="font-bold">4. {t('how_to.json_get_data_web9')}</p>
        <p>{t('how_to.json_get_data_web10')}</p>
        <img src={json6} alt="screenshot of excel"/>
      </div>

    </>
  )
}

const PowerbiXML = ()=>{

  const {t} = useTranslation()

  return (
    <>
      <div className="space-y-6">
        <div className="flex space-x-6">
          <span className="uppercase"><Link to="/howto" className="hover:underline hover:text-sapphire-blue">HowTo</Link></span>
          <span>&gt;</span>
          <span className="uppercase text-sapphire-blue underline">XML API TUTORIAL: Power BI</span>
        </div>
        <h1 className="text-sapphire-blue font-bold">XML API Tutorial: Power BI</h1>
      <p className="text-3xl font-light ">Pull your data into Power BI using XML API</p>
      </div>
      <hr/>

      <h2 className="text-2xl my-12">{t('how_to.json_import_data')}</h2>

      <div className="space-y-4 mt-6">
        <p className="font-bold">1. {t('how_to.json_get_data_web')}</p>
        <p>{t('how_to.json_get_data_web1')}"</p>
        <img src={xmlbi1} alt="screenshot of excel"/>
      </div>

      <div className="space-y-4 mt-6">
        <p className="font-bold">2. {t('how_to.json_get_data_web2')}</p>
        <img src={xmlbi2} alt="screenshot of excel"/>
      </div>


      <div className="space-y-4 mt-6">
        <p className="font-bold">3. {t('how_to.json_get_data_web3')}</p>
        <p>{t('how_to.xml_get_data2')}</p>
        <img src={xmlbi3} alt="screenshot of excel"/>
      </div>


      <div className="space-y-4 mt-6">
        <p className="font-bold">4. {t('how_to.json_get_data_web9')}</p>
        <p>{t('how_to.powerbi_pull_your_data10')}</p>
        <img src={xmlbi4} alt="screenshot of excel"/>
      </div>

      <hr/>

      <h2 className="text-2xl my-12">{t('how_to.json_import_data')}</h2>
      <p>{t('how_to.powerbi_pull_your_data10')}</p>
      <div className="space-y-4 mt-6">
        <p className="font-bold">1. {t('how_to.xml_powerbi_pull_data')}</p>
        <img src={xml5} alt="screenshot of excel"/>
      </div>

    </>
  )
}

const PowerbiJSON = ()=>{

  const {t} = useTranslation()

  return (
    <>
      <div className="space-y-6">
        <div className="flex space-x-6">
          <span className="uppercase"><Link to="/howto" className="hover:underline hover:text-sapphire-blue">HowTo</Link></span>
          <span>&gt;</span>
          <span className="uppercase text-sapphire-blue underline">JSON API TUTORIAL:Power BI</span>
        </div>
        <h1 className="text-sapphire-blue font-bold">JSON API Tutorial: Power BI</h1>
      <p className="text-3xl font-light pt-12">{t('how_to.powerbi_pull_your_data')}</p>
      </div>
      <hr/>

      <h2 className="text-2xl my-12">{t('how_to.json_import_data')}</h2>

      <div className="space-y-4 mt-6">
        <p className="font-bold">1. {t('how_to.json_get_data_web')}</p>
        <p>{t('how_to.powerbi_pull_your_data1')}</p>
        <img src={jsonbi1} alt="screenshot of powerbi"/>
      </div>

      <div className="space-y-4 mt-6">
        <p className="font-bold">2. {t('how_to.json_get_data_web2')}</p>
        <img src={jsonbi2} alt="screenshot of powerbi"/>
      </div>


      <div className="space-y-4 mt-6">
        <p className="font-bold">3. {t('how_to.json_get_data_web3')}</p>
        <p>3-1. {t('how_to.json_get_data_web4')}</p>
        <img src={jsonbi3} alt="screenshot of powerbi"/>
        <p>3-2. {t('how_to.json_get_data_web5')}</p>
        <img src={jsonbi4} alt="screenshot of powerbi"/>
        <p>3-3. {t('how_to.powerbi_pull_your_data6')}</p>
        <img src={jsonbi5} alt="screenshot of powerbi"/>
        <p>3-4. {t('how_to.powerbi_pull_your_data7')}</p>
        <img src={jsonbi6} alt="screenshot of powerbi"/>
        <p>3-5.	{t('how_to.powerbi_pull_your_data8')}</p>
        <img src={jsonbi7} alt="screenshot of powerbi"/>
      </div>


      <div className="space-y-4 mt-6">
        <p className="font-bold">4. {t('how_to.powerbi_pull_your_data9')}</p>
        <p>{t('how_to.powerbi_pull_your_data10')}</p>
        <img src={jsonbi8} alt="screenshot of excel"/>
      </div>

    </>
  )
}

const ApiStructure= ()=>{

  const {t} = useTranslation();

  return (
    <>
      <div className="space-y-8">
        <div className="flex space-x-6">
          <span className="uppercase"><Link to="/howto" className="hover:underline hover:text-sapphire-blue">HowTo</Link></span>
          <span>&gt;</span>
          <span className="uppercase text-sapphire-blue underline">API Structure Tutorial</span>
        </div>
        <h1 className="text-sapphire-blue font-bold">{t('how_to.api_structure')}</h1>
        <p className="text-3xl font-light">{t('how_to.api_structure_pull_data')}</p>
        <hr/>


          <h2 className="text-4xl">{t('how_to.api_structure_pull_data1')}</h2>
          <p><code className="text-roboto">https://api.psdata.un.org/public</code></p>
          <hr/>

          <h2 className="text-4xl">{t('how_to.api_structure_pull_data2')} (/data)</h2>

          <div className="space-y-2">
            <p>{t('how_to.api_structure_pull_data3')}:</p>
            <p className="font-bold italic">{t('how_to.api_structure_pull_data4')}</p>
            <p><code className="text-roboto">https://api.psdata.un.org/public/data/&#123;datasetid&#125;/&#123;outputformat&#125;?&#123;optionalparameters&#125;</code></p>
          </div>

          <div className="space-y-2">
            <p className="text-base font-bold">Dataset ID ({t('how_to.api_structure_pull_data5')})</p>
            <p>Dataset ID {t('how_to.api_structure_pull_data6')}</p>
          </div>

          <div className="space-y-2">
            <p className="text-base font-bold">{t('how_to.api_structure_pull_data7')} ({t('how_to.api_structure_pull_data5')})</p>
            <ol>
              <li>JSON</li>
              <li>XML</li>
              <li>CSV</li>
            </ol>

            <p className="text-base font-bold italic">{t('how_to.api_structure_pull_data8')}</p>
            <p><code className="text-roboto">https://api.psdata.un.org/public/data/DPO-QIP/json </code></p>
            <p><code className="text-roboto">https://api.psdata.un.org/public/data/DPO-UCLATEST/xml</code></p>
          </div>

          <div className="space-y-2">
            <p className="text-lg font-bold">{t('how_to.api_structure_pull_data9')}</p>
            <p>{t('how_to.api_structure_pull_data10')}</p>
          </div>

          <div className="space-y-2">
            <p className="text-base font-bold">1. {t('how_to.api_structure_pull_data11')}</p>
            <p>{t('how_to.api_structure_pull_data12')} :</p>
            <ol>
              <li><code className="text-roboto">per_page=:</code> {t('how_to.api_structure_pull_data13')}</li>
              <li><code className="text-roboto">page_num=:</code> {t('how_to.api_structure_pull_data14')} </li>
            </ol>

            <p className="text-base font-bold">Example</p>
            <p><code className="text-roboto">https://api.psdata.un.org/public/data/DPPADPOSS-FATALITIES/xml?page_num=2&per_page=30</code></p>

            </div>
            <div className="space-y-2">

            <p className="text-base font-bold">2. {t('how_to.api_structure_pull_data15')}</p>
            <p>{t('how_to.api_structure_pull_data16')}:</p>
            <p><code className="text-roboto">filter=&#123;column1Name&#125;:&#123;val1&#125;,&#123;val2&#125;,&#123;val3&#125;...;&#123;column2Name&#125;:&#123;val1&#125;....;.....</code></p>
            <p className="text-base font-bold">Example</p>
            <p><code className="text-roboto">https://api.psdata.un.org/public/data/DPPADPOSS-PKO/xml?filter=Mission_Acronym:MINUSMA,MONUSCO</code></p>

          </div>

          <hr />

          <h2 className="text-4xl">{t('how_to.metadata')} (/metadata)</h2>
          <p>{t('how_to.api_structure_pull_data17')}:</p>
          <div className="space-y-2">
            <p className="text-base font-bold italic">{t('how_to.api_structure_pull_data4')}</p>
            <p><code className="text-roboto">https://api.psdata.un.org/public/metadata/&#123;datasetid&#125;?&#123;optionalparameters&#125;</code></p>
          </div>

          <div className="space-y-2">

          <p className="text-base font-bold">Dataset ID ({t('how_to.api_structure_pull_data5')})</p>
            <p>Dataset IDs can be found under each dataset page. Pass 'all' to fetch metadata for all datasets </p>
            <p className="font-bold italic">Example</p>
            <p><code className="text-roboto">https://api.psdata.un.org/public/metadata/DPO-QIP</code></p>
          </div>

          <div className="space-y-2">
            <p className='text-lg font-bold'>{t('how_to.api_structure_pull_data9')}</p>
            <p>{t('how_to.api_structure_pull_data10')}</p>
          </div>

          <div className="space-y-2">
            <p className="text-base font-bold">1. {t('how_to.api_structure_pull_data19')}</p>
            <p>{t('how_to.api_structure_pull_data20')}</p>
            <p className="font-bold italic">{t('how_to.api_structure_pull_data4')}</p>
            <p><code className="text-roboto">column=&#123;comma separated column name&#125;</code></p>
            <p className="text-base italic font-bold">Example</p>
            <p><code className="text-roboto">https://api.psdata.un.org/public/metadata/all/columns=Dataset ID,Tags</code></p>
          </div>

          <div className="space-y-2">
            <p className="text-base font-bold">2. {t('how_to.api_structure_pull_data21')}</p>
            <p>{t('how_to.api_structure_pull_data22')}</p>
            <p className="italic font-bold">{t('how_to.api_structure_pull_data4')}</p>
            <p><code className="text-roboto">type=external</code></p>
            <p className="text-base font-bold italic">Example</p>
            <p><code className="text-roboto">https://api.psdata.un.org/public/metadata/all?type=external</code></p>
          </div>

          <div className="space-y-2">
            <p className="text-base font-bold">3. {t('how_to.api_structure_pull_data23')}</p>
            <p>{t('how_to.api_structure_pull_data24')}:</p>
            <p className="italic font-bold">{t('how_to.api_structure_pull_data4')}</p>
            <p><code className="text-roboto">themes=&#123;comma separated values&#125;</code></p>
            <p className="text-base font-bold italic">Example</p>
            <p><code className="text-roboto">https://api.psdata.un.org/public/metadata/all?columns=Themes&themes=Peackeeping,Peacebuilding </code></p>
          </div>

      </div>


    </>
  )
}
