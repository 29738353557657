import { useState, useEffect } from 'react';
import { fetchBlogList, fetchBlogFile } from '../../shared/utils/fetchapi';

import './styles.css';


import ReactMarkdown from "react-markdown";
import rehypeRaw from 'rehype-raw';
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
import i18n from "../../i18n";

const CACHE = {};

export default function Blog ({match}) {
  const [blog, setBlog] = useState([]);
  const [date, setDate] = useState(null);
  const [blogList, setBlogList] = useState([]);
  const location = useLocation();
  const [language, setLanguage] = useState('en');

  const {i18n} = useTranslation()
  document.body.dir = i18n.dir()

  const handleLanguageChange = () => {
    getBlog(true);
  }

  useEffect(() => {
    i18n.on('languageChanged', handleLanguageChange);

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [i18n, handleLanguageChange]);

  const getLanguage = () => i18n.language || window.localStorage.i18nextLng

  const blogId = match.params.blog;

  useEffect(()=>{
    window.scrollTo(0, 0);
  }, [])


  let getBlog = async(flag) => {
    /*if(CACHE.blog!== undefined){
      setBlog(CACHE.blog)
      return
    }*/
    const queryParams = new URLSearchParams(location.search);
    const language = flag  ? getLanguage() : queryParams.get('lang') || getLanguage();

    if (!flag){
      i18n.changeLanguage(language)
    }

    const response = await fetchBlogFile(blogId, language);

    CACHE.blog = response.data
    setBlog(response.data);
  }


  useEffect(()=>{
    getBlog(false);
  }, []);


  let getBlogList = async () => {
    if (CACHE.blog !== undefined) {
      setBlogList(CACHE.blog);
      return
    }
    const response = await fetchBlogList();
    let distinct = []
    let unique = []

    for(let i = 0;i < response.data.length; i++) {
      if(!unique.includes(response.data[i]['link'])){
        unique.push(response.data[i].link)
        distinct.push(response.data[i])
      }
    }

    let currentBlog = distinct.filter(blog=>blog["link"]===`${blogId}/${blogId}`)
    setDate(currentBlog[0].date)

    // setBlogList(distinct);
  };

  useEffect(() => {
    getBlogList();
  }, []);

  useEffect(()=>{
    const title= document.getElementById('title')
    const date= document.getElementById('blog-date')
    if(title!== null){
      date.innerHTML = title.innerHTML
    }
  }, [blog])

  return (
    <>
    <div className="w-screen">
    </div>
    <div className="container mx-auto header grid grid-cols-12 gap-x-6 px-6 my-20 pt-20" >
      <div className="col-span-12 md:col-span-3">
        <div className="grid grid-cols-3 gap-x-6 sticky" style={{top:124}}>
          <div className="col-span-3 md:col-span-2 md:col-start-2 space-y-2">
            {date &&
            <>
              <p>Date</p>
              <p id="blog-date" className="text-sapphire-blue">{date}</p>
            </>
            }
          </div>
        </div>
      </div>

      {/* CONTENT */}
      <div className="col-span-12 md:col-span-7 gap-x-6">

      {/* CONTENT HEADER */}
        <div className="w-full space-y-8">
          {/* CONTENT BODY */}
          <div className="blog-body">

          { blog &&
          <ReactMarkdown rehypePlugins={[rehypeRaw]} children={blog} />
          }
          </div>

        </div>
      </div>
    </div>
    </>

  )
}