import { useEffect, useState } from "react";
import refresh from "../../assets/refreshIcon.svg";
import RightArrowYellow from "../../assets/right_arrow_yellow.svg";

import { PublicAccess, RestrictedAccess, AllAccess } from "./AccessIcon";

import { AuthenticatedTemplate } from "@azure/msal-react";

import { useFetchThemes } from "../../shared/hooks/useFetchThemes";
import {useTranslation} from "react-i18next";

const CACHE = {};

/**
 * Container for Search page Sidebar
 * Contains the search/ filter queries
 */
export default function SidebarV2({
  searchQuery,
  setTypeFilter,
  selectedFilters,
  setSelectedFilters,
  setShowMissionFilter,
}) {
  const [isActive, setActive] = useState(false);
  const [hasFilters, setHasFilters] = useState(null);
  const [deserializedFilters, setDeserializedFilters] = useState(null);
  const [deserializedCountries, setDeserializedCountries] = useState({
    Asia: [],
    Europe: [],
    Africa: [],
    Oceania: [],
    Americas: [],
    Antarctica: [],
  });
  const [deserializedMissions, setDeserializedMissions] = useState(null);
  const [selectedCountries, setSelectedCountries] = useState(null);

  const themes = useFetchThemes();

  const toggleClass = () => {
    setActive(!isActive);
  };

  const {t} = useTranslation()

  const passToParent = (e) => {
    /*
     * Pass Themes to Parent
     */
    e.target.blur();

    if (!selectedFilters) {
      // console.log(selectedFilters);
      return;
    }

    /* MUST CONVERT STRING TO OBJ */
    let selectedFiltersOBJ =
      typeof selectedFilters === "string"
        ? JSON.parse(selectedFilters)
        : selectedFilters;

    let query = e.target.innerText.toLowerCase();
    let selectedFilters_ = selectedFiltersOBJ;
    let selectedThemes = selectedFiltersOBJ["themes"];
    const idx = selectedThemes.indexOf(query);
    if (idx === -1) {
      selectedThemes.push(query);
    } else {
      selectedThemes.splice(idx, 1);
    }
    selectedFilters_["themes"] = selectedThemes;

    if (e.target.classList.contains("text-sapphire-blue")) {
      e.target.classList.remove("text-sapphire-blue");
      e.target.classList.remove("border-sapphire-blue");
      e.target.classList.remove("activeFilterButton");
    } else {
      e.target.classList.add("text-sapphire-blue");
      e.target.classList.add("border-sapphire-blue");
      e.target.classList.add("activeFilterButton");
      e.target.classList.remove("border-neutral-76");
    }

    toggleClass();
    /* MUST SERIALIZE OBJ TO TRIGGER RE-RENDER */
    setSelectedFilters(JSON.stringify(selectedFilters_));
  };

  const handleReset = () => {
    /* Removes all filters */
    let selectedFiltersOBJ =
      typeof selectedFilters === "string"
        ? JSON.parse(selectedFilters)
        : selectedFilters;
    selectedFiltersOBJ["themes"] = [];
    selectedFiltersOBJ["search"] = [];
    selectedFiltersOBJ["countries"] = [
      { Asia: [] },
      { Europe: [] },
      { Africa: [] },
      { Oceania: [] },
      { Americas: [] },
      { Antarctica: [] },
    ];
    selectedFiltersOBJ["missions"] = [];

    setDeserializedCountries({
      Asia: [],
      Europe: [],
      Africa: [],
      Oceania: [],
      Americas: [],
      Antarctica: [],
    });
    setSelectedFilters(JSON.stringify(selectedFiltersOBJ));
    setHasFilters(false);

    const selectedButtons =
      document.getElementsByClassName("activeFilterButton");

    for (let i = 0; i < selectedButtons.length; i++) {
      selectedButtons[i].classList.remove("text-sapphire-blue");
      selectedButtons[i].classList.remove("border-sapphire-blue");
      selectedButtons[i].classList.remove("activeFilterButton");
    }
  };

  const handleClearThemes = () => {
    /* Clear theme filtetrs */
    let response = deserializedFilters;

    response["themes"] = [];

    const selectedButtons =
      document.getElementsByClassName("activeFilterButton");

    for (let i = 0; i < selectedButtons.length; i++) {
      selectedButtons[i].classList.remove("text-sapphire-blue");
      selectedButtons[i].classList.remove("border-sapphire-blue");
      selectedButtons[i].classList.remove("activeFilterButton");
    }
    setDeserializedFilters(response);
    setSelectedFilters(JSON.stringify(response));

    setActive();
  };

  const removeCountry = (e) => {
    /* Remove all countries of continent */
    let deserializedCountries_ = deserializedCountries;
    let selectedFilters_ = JSON.parse(selectedFilters);

    let idx = deserializedCountries_[e.target.dataset.continent].indexOf(
      e.target.name
    );
    deserializedCountries_[e.target.dataset.continent].splice(idx, 1);

    selectedFilters_["countries"] = deserializedCountries_;
    setSelectedFilters(JSON.stringify(selectedFilters_));
  };

  const removeCountryGroup = (e) => {
    /* Remove all countries of continent */
    let deserializedCountries_ = deserializedCountries;
    deserializedCountries_[e.target.name] = [];
    let selectedFilters_ = JSON.parse(selectedFilters);
    selectedFilters_["countries"] = deserializedCountries_;
    setSelectedFilters(JSON.stringify(selectedFilters_));
    // setDeserializedCountries(deserializedCountries_)
  };

  const handleClearCountriesBtn = (e) => {
    /* removes all selected countries */
    let selectedFilters_ = JSON.parse(selectedFilters);
    selectedFilters_["countries"] = {
      Asia: [],
      Europe: [],
      Africa: [],
      Oceania: [],
      Americas: [],
      Antarctica: [],
    };
    setSelectedFilters(JSON.stringify(selectedFilters_));
  };

  const handleRemoveMission = (e) => {
    /* Remove mission from selected filters */
    let selectedFilters_ = JSON.parse(selectedFilters);
    let deserializedMissions = selectedFilters_["missions"];

    let idx = deserializedMissions.indexOf(e.target.name);

    deserializedMissions.splice(idx, 1);

    selectedFilters_["missions"] = deserializedMissions;
    setDeserializedMissions(deserializedMissions);
    setSelectedFilters(JSON.stringify(selectedFilters_));
  };

  const handleRemoveAllMission = (e) => {
    /* Remove ALL missions from selected filters */
    let selectedFilters_ = JSON.parse(selectedFilters);

    selectedFilters_["missions"] = [];
    setDeserializedMissions([]);
    setSelectedFilters(JSON.stringify(selectedFilters_));
  };

  useEffect(() => {
    /* deserialized selected filters on change */
    let selectedFiltersOBJ =
      typeof selectedFilters === "string"
        ? JSON.parse(selectedFilters)
        : selectedFilters;
    setDeserializedFilters(selectedFiltersOBJ);
  }, [selectedFilters]);

  useEffect(() => {
    /* scroll to top on load */
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    /* update deserialized countries whenever selected filters change */
    if (!deserializedFilters) return;
    let selectedFiltersOBJ =
      typeof selectedFilters === "string"
        ? JSON.parse(selectedFilters)
        : selectedFilters;

    const countries = selectedFiltersOBJ["countries"];
    const missions = selectedFiltersOBJ["missions"];
    let allCountries = {};

    /* Sometimes effect is not fast enough to convert array to obj */
    if (Array.isArray(countries)) {
      for (let i in countries) {
        let key = Object.keys(countries[i]);
        allCountries[key] = countries[i][key];
      }
    } else {
      for (let i in countries) {
        allCountries[i] = countries[i];
      }
    }
    setDeserializedCountries(allCountries);
    setDeserializedMissions(missions);
  }, [selectedFilters]);

  useEffect(() => {
    /* Set if has filters to highlight reset filters */

    let selectedFiltersOBJ =
      typeof selectedFilters === "string"
        ? JSON.parse(selectedFilters)
        : selectedFilters;

    if (
      selectedFiltersOBJ["search"].length === 0 &&
      selectedFiltersOBJ["missions"].length === 0 &&
      selectedFiltersOBJ["themes"].length === 0 &&
      selectedFiltersOBJ["countries"].length === 0
    ) {
      setHasFilters(false);
    } else {
      setHasFilters(true);
    }
  });

  return (
    <div className="text-black">
      <div className="flex flex-col space-y-6 px-8">
        <div className="flex flex-row justify-between items-center">
          <span className="text-neutral-30 text-xs font-bold">{t('sidebar.filter_by')}:</span>
          <div className="flex flex-row space-x-2 items-center text-xs hover:opacity-70 transition-opacity">
            <span
              onClick={handleReset}
              className={hasFilters ? "text-black" : "text-neutral-76"}
              role="button"
            >
              {t('sidebar.reset_filter')}
            </span>
            <span
              className={searchQuery.length > 0 ? "opacity-100" : "opacity-10"}
            >
              <img alt="" src={refresh} />
            </span>
          </div>
        </div>

        <AuthenticatedTemplate>
          <hr className="border-neutral-76" />
          <div className="flex space-x-4">
            <PublicAccess setTypeFilter={setTypeFilter} />
            <RestrictedAccess setTypeFilter={setTypeFilter} />
            <AllAccess setTypeFilter={setTypeFilter} />
          </div>
        </AuthenticatedTemplate>

        <hr className="border-neutral-76" />

        <div className="flex flex-row justify-between">
          <span className="uppercase action_md">{t('sidebar.themes')}</span>
          <span
            role="button"
            className={
              deserializedFilters && deserializedFilters["themes"].length > 0
                ? "text-xs  text-black hover:opacity-50 transition-opacity"
                : "text-xs text-neutral-76"
            }
            onClick={handleClearThemes}
          >
            {t('sidebar.clear_all')}
          </span>
        </div>
        <div className="flex flex-row flex-wrap">
          {themes
            ? themes.map((i, k) => {
                return (
                  <button
                    className="btn_sm text-black border m-1 themebutton hover:opacity-70 transition-opacity"
                    onClick={(e) => passToParent(e)}
                    key={k}
                    name={i.themes}
                  >
                    {i.themes}
                  </button>
                );
              })
            : "Loading themes"}
        </div>

        <hr className="border-neutral-70" />

        <div>
          <div>
            <div className="flex flex-row justify-between mb-4 items-center">
              <span
                onClick={() => setShowMissionFilter(true)}
                val="cty"
                className="uppercase action_md hover:opacity-70 transition-opacity"
                role="button"
              >
                {t('sidebar.missions_covered')}
              </span>
              <span>
                <img alt="" src={RightArrowYellow} />
              </span>
              <span
                role="button"
                className={
                  deserializedMissions && deserializedMissions.length > 0
                    ? "text-sm  text-black hover:opacity-50 transition-opacity"
                    : "text-sm text-neutral-76"
                }
                onClick={handleRemoveAllMission}
              >
                {t('sidebar.clear_all')}
              </span>
            </div>

            <div>
              {deserializedMissions !== null ||
              (deserializedMissions && deserializedMissions.length > 0) ? (
                deserializedMissions.map((d, k) => {
                  return (
                    <button
                      className="btn_sm text-sapphire-blue border border-sapphire-blue m-1 hover:opacity-70 transition-opacity"
                      key={k}
                      name={d}
                      onClick={handleRemoveMission}
                    >
                      {d} x
                    </button>
                  );
                })
              ) : (
                <button className="btn_sm text-sapphire-blue border border-sapphire-blue m-1 hover:opacity-70 transition-opacity">
                  {t('sidebar.all_missions')}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
