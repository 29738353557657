import React, {createRef} from 'react';


import { Column, Table, SortDirection, AutoSizer } from "react-virtualized";
import "react-virtualized/styles.css";
import _ from "lodash";
import './styles.css'

// import Fuse from 'fuse.js';

// Table data as a array of objects
let list = [];

let columns = [];

// let fuse;
let results;
let searchresults;


class Virtualized extends React.Component {

  constructor(props) {
    super(props);

    this.tableElement = createRef()

    list = props.dataset;

    const sortBy = "id";
    const sortDirection = SortDirection.ASC;
    const sortedList = this._sortList({ sortBy, sortDirection });
    let columns = []

    this.state = {
      sortBy,
      sortDirection,
      sortedList,
      columns
    };
  }

  componentDidMount() {
    let keys=[];
    for(var key in list[0]){
      keys.push(key)
    }
    columns = keys;

    let headers = document.getElementsByClassName("ReactVirtualized__Table__headerColumn");
    for(let i = 0; i < headers.length; i++){
      headers[i].classList.add("flex")
    }


    let columnName = document.getElementsByClassName("ReactVirtualized__Table__headerRow");
    columnName[0].classList.add("border-b")
    columnName[0].classList.add("border-un-blue")
    columnName[0].classList.add("pb-4")
    columnName[0].classList.add("mb-4")

    // let table = document.getElementsByClassName("ReactVirtualized__Grid ReactVirtualized__Table__Grid")
    // table[0].scrollTop = 50
    // console.log(table[0].scrollTop)


  }

  componentDidUpdate() {
    let headers = document.getElementsByClassName("ReactVirtualized__Table__sortableHeaderColumn");
    for(let i = 0; i < headers.length; i++){
      headers[i].classList.add("flex")
    }

    let table = document.getElementsByClassName("ReactVirtualized__Grid ReactVirtualized__Table__Grid")
    table[0].scrollTop = 50
  }

  render() {
    return (
      <div style={{ height: 480 }}>
        <AutoSizer>
          {({ height, width }) => (
            <Table
              width={columns.length * 200}
              height={height}
              headerHeight={40}
              rowHeight={50}
              sort={this._sort}
              sortBy={this.state.sortBy}
              sortDirection={this.state.sortDirection}
              rowCount={this.state.sortedList.length}
              rowGetter={({ index }) => this.state.sortedList[index]}
              ref={this.tableElement}
            >
              {columns &&
                columns.map((column, key)=>{
                  return(
                    <Column key={key} label={column} dataKey={column} width={200} />
                  )
                })

              }

            </Table>
          )}
        </AutoSizer>
      </div>
    );
  }

  _sortList = ({ sortBy, sortDirection }) => {
    let newList = _.sortBy(list, [sortBy]);
    if (sortDirection === SortDirection.DESC) {
      newList.reverse();
    }
    return newList;
  };

  _sort = ({ sortBy, sortDirection }) => {
    const sortedList = this._sortList({ sortBy, sortDirection });
    this.setState({ sortBy, sortDirection, sortedList });
  };
}

export default Virtualized;