import Iframe from 'react-iframe';

export default function Powerbi({link}) {

  return (

    <Iframe url={link}
      id="pbi"
      width="100%"
      height="500px"
      display="initial"
      position="relative" />

  )
}