import React, { useEffect } from "react";
import { Router, Switch, Route, withRouter } from "react-router-dom";
import "./styles/tailwind.css";
import "./styles/App.css";

import NavBar from "./components/Global/NavBar";
import Footer from "./components/Global/Footer";
import Homepage from "./containers/Homepage.js";
import SearchContainer from "./containers/SearchContainer.js";

import SearchContainerV2 from "./containers/SearchContainerV2.js";

import Datasetpage from "./containers/Datasetpage.js";
import AdditionalResources from "./containers/AdditionalResources.js";
import DataThemes from "./containers/DataThemes.js";
import DataStories from "./containers/DataStories.js";
import About from "./containers/About.js";
import Legal from "./containers/Legal.js";
import Contact from "./containers/Contact.js";

import HowTo from "./containers/HowTo.js";
import Instructions from "./containers/Instructions.js";
import Blog from "./containers/Blog";

import NotFound from "./containers/NotFound";


import ReactGA from "react-ga";
import { createBrowserHistory } from "history";

import { encrypt, decrypt } from "./shared/utils/encrypt";
import NewsLetter from "./containers/NewsLetter";
import MenuBar from "./components/Global/MenuBar";


export default function App({ msalInstance }) {
  // TESTING TRACKING CODE
  const trackingId = "UA-191755380-1";

  // PRODUCTION TRACKING IDzs
  // const trackingId = "UA-191755380-2"

  ReactGA.initialize(trackingId);
  const history = createBrowserHistory();
  ReactGA.pageview(window.location.pathname);

  history.listen((location) => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  });

  useEffect(() => {
    const account = msalInstance.getActiveAccount();


    if (account) {
      const username = encrypt(account.username);
      ReactGA.set({ userId: username });
      ReactGA.set({ UserId: username });
      ReactGA.set({ dimension1: username });

    }
  }, []);

  return (
    <>
      <Router history={history}>
        <NavBar />
        <MenuBar />
        <Switch>
          <Route exact path="/" component={Homepage} />

          {/* <Route exact path="/search" component={SearchContainer} /> */}

          <Route exact path="/search" component={SearchContainerV2} />

          <Route exact path="/resources" component={AdditionalResources} />
          <Route exact path="/howto" component={HowTo} />
          <Route exact path="/themes" component={DataThemes} />
          <Route path="/dataset/:dataset" component={withRouter(Datasetpage)} />

          <Route exact path="/stories" component={DataStories} />
          <Route exact path="/about" component={withRouter(About)} />
          <Route exact path="/contact" component={withRouter(Contact)} />
          <Route exact path="/newsletter" component={withRouter(NewsLetter)} />
          <Route exact path="/legal/:tab" component={Legal} />

          <Route exact path="/howto/:format" component={Instructions} />

          <Route exact path="/blog/:blog" component={Blog} />
          <Route exact path="/blog/:blog/:blog" component={Blog} />

          <Route component={NotFound} />
        </Switch>
        <Footer />
      </Router>
    </>
  );
}
