import axios from "axios";

/*********************************
 ***     FOR API CONSUMPTION    ***
 *********************************/

/**
 * These environmental variables are injected during Docker build
 * Callsource is the Azure environment - Options are WebsiteDev, Websitestage, prodwebsite
 */
const callsource = process.env.REACT_APP_CALLSOURCE || "WebsiteDev";
const ApiManagement =
    process.env.REACT_APP_API_MANAGEMENT ||
    "https://dppa-p-apimanagement-01.azure-api.net/staging";
const BlogApiManagement = `${ApiManagement}/public/blogs`;

/**
 * Outputs metadata for a dataset
 *
 * @param {string} metadata The dataset ID
 * @returns {Object[]} The metadata
 */

export const fetchMetadata = (metadata) => {
    const apiURL = `${ApiManagement}/public/metadata/${metadata}?source=${callsource}`;
    return axios
        .get(apiURL)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            console.error(err);
        });
};

/**
 * This function returns all data from a Dataset
 *
 * @param {string} dataset The dataset ID
 * @param {string} format The file type Options are: json, xml, csv
 * @returns {Object} All the data from a dataset
 */

export const fetchAll = (dataset, format) => {
    const apiURL = `${ApiManagement}/public/data/${dataset}/${format}?source=${callsource}`;
    return axios
        .get(apiURL)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            console.error(err);
        });
};

/**
 * This function makes a paginated request for a dataset's data
 *
 * @param {string} dataset The dataset id
 * @param {string} format The data format
 * @param {int} page_num The page number
 * @param {int} per_page The number of rows each page
 * @returns {Object} The paginated data
 */
export const fetchPage = (dataset, format, page_num, per_page) => {
    const apiURL = `${ApiManagement}/public/data/${dataset}/${format}?page_num=${page_num}&per_page=${per_page}&source=${callsource}`;
    return axios
        .get(apiURL)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            console.error(err);
        });
};

/**
 * Outputs a filtered request for data
 *
 * @param {string} dataset The dataset ID
 * @param {string} format The data format. Options are json, xml, csv
 * @param {string} filter The column name to filter
 * @param {string} query The value to filter
 * @returns {Object} The filtered data
 */
export const fetchFilter = (dataset, format, filter, query) => {
    const apiURL = `${ApiManagement}/public/data/${dataset}/${format}?filter=${filter}:${query}&source=${callsource}`;

    return axios
        .get(apiURL)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            console.error(err);
        });
};

/**
 * This function returns all external resources
 * Used to populate "Additional Resource" page
 *
 * @returns {Object[]}
 */
export const fetchExternalResources = () => {
    const apiURL = `${ApiManagement}/public/metadata/all?type=external&source=${callsource}`;

    return axios
        .get(apiURL)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            console.error(err);
        });
};

/**
 * Returns the themes from themes API
 * Used to populate "Themes" page and "Search" page filters
 *
 * @returns {Object[]}
 */
export const fetchThemes = () => {
    const apiURL = `${ApiManagement}/public/admdata/ADMIN-THEMES/json?source=${callsource}`;

    return axios
        .get(apiURL)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            console.error(err);
        });
};

/**
 * Returns datasets with a tagged theme
 * Uses the metadata API
 * Used for the Discover More tab
 *
 * @param {string} theme Theme to filter
 * @returns {Object[]}
 */
export const fetchSimilarDatasets = (theme) => {
    let themeQuery = "";

    for (const t in theme) {
        themeQuery += theme[t].Theme;
        themeQuery += ",";
    }
    const apiURL = `${ApiManagement}/public/metadata/all?Themes=${themeQuery}&columns=Name,Description,Dataset%20ID&source=${callsource}`;

    return axios
        .get(apiURL)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            console.error(err);
        });
};

/**
 * Returns External datasets with a tagged theme
 * Used for "Discover More" tab
 *
 * @param {*} theme Theme to filter
 * @returns {Object[]}
 */
export const fetchSimilarExternal = (theme) => {
    let themeQuery = "";

    for (const t in theme) {
        themeQuery += theme[t].Theme;
        themeQuery += ",";
    }
    const apiURL = `${ApiManagement}/public/metadata/all?type=external&themes=${themeQuery}&columns=Link%20to%20external%20dataset,Name,Description&source=${callsource}`;

    return axios
        .get(apiURL)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            console.error(err);
        });
};

/**
 * Returns countries from Countries API
 * Used to populate Countries filter on Search
 *
 * @returns {Object[]}
 */
export const fetchCountryList = () => {
    const apiURL = `${ApiManagement}/public/admdata/ADMIN-COUNTRIES/JSON?source=${callsource}`;

    return axios
        .get(apiURL)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            console.error(err);
        });
};

/**
 * This will download the dataset data
 *
 * @param {string} id The dataset id
 * @param {string} format The requested data format. Options are json, xml, csv
 * @param {string} accessType The access level of a dataset
 * @param {string} accessToken The MSAL access token. Required for restricted datasets
 */
export const downloadDataset = (id, format, accessType, accessToken) => {
    const ApiManagementPublicDownload = `${ApiManagement}/${accessType}/data/${id}/${format}?download=true`;
    const config = {
        headers: {Authorization: `Bearer ${accessToken}`},
    };
    document.getElementById("download-progress").innerHTML =
        "Download started...";
    axios(
        {
            url: ApiManagementPublicDownload,
            method: "GET",
            responseType: "blob", //important
            headers: {Authorization: `Bearer ${accessToken}`},
        },
        {},
        config
    )
        .then(({data}) => {
            const downloadUrl = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.setAttribute("download", `${id}.${format}`); //any other extension
            document.body.appendChild(link);
            link.click();
            link.remove();
            document.getElementById("download-progress").innerHTML = "";
        })
        .catch((err) => {
            console.error(err);
        });
};

/**
 * Returns missions from mission sharepoint list
 * Used to populate missions filter on search page
 *
 * @returns {Object[]} Missions
 */
export const fetchMissionList = () => {
    const apiURL = `${ApiManagement}/public/admdata/ADMIN-MISSIONS/json?source=${callsource}`;

    return axios
        .get(apiURL)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            console.error(err);
        });
};

// ! FOR INTERNAL DATA APIs

/**
 * Returns metadata from Internal metadata API
 *
 * @param {*} metadata Dataset ID
 * @param {*} token MSAL Access token
 * @returns {Object[]}
 */
export const fetchMetadataInternal = (metadata, token) => {
    const apiURL = `${ApiManagement}/internal/metadata/${metadata}?source=${callsource}`;
    const config = {
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
        }
    };

    return axios
        .get(apiURL, config)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            console.error(err);
        });
};

/**
 * Returns data from Internal Data API
 *
 * @param {string} dataset Dataset ID
 * @param {string} format Data format. Options are json, xml, csv
 * @returns
 */
export const fetchAllInternal = (dataset, format) => {
    const apiURL = `${ApiManagement}/internal/data/${dataset}/${format}?source=${callsource}`;

    return axios
        .get(apiURL)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            console.error(err);
        });
};

/**
 * Returns Paginated Data from Internal Dataset API
 *
 * @param {string} dataset Dataset ID
 * @param {string} format Data format. Options are json, xml, csv
 * @param {int} page_num Paginated page
 * @param {int} per_page Rows per page
 * @param {string} accessToken MSAL Access token
 * @returns {Object[]}
 */
export const fetchPageInternal = (
    dataset,
    format,
    page_num,
    per_page,
    accessToken
) => {
    const apiURL = `${ApiManagement}/internal/data/${dataset}/${format}?page_num=${page_num}&per_page=${per_page}&source=${callsource}`;

    const config = {
        headers: {Authorization: `Bearer ${accessToken}`},
        "Content-Type": "application/x-www-form-urlencoded",
    };
    return axios
        .get(apiURL, config)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            console.error(err);
        });
};

/**
 * Returns datasets with a tagged theme
 * @param {string} theme Dataset ID
 * @param {string} accessToken MASAL access token
 */
export const fetchSimilarDatasetsInternal = (theme, accessToken) => {
    let themeQuery = "";

    for (const t in theme) {
        themeQuery += theme[t].Theme;
        themeQuery += ",";
    }
    const apiURL = `${ApiManagement}/internal/metadata/all?Themes=${themeQuery}&columns=Name,Description,Dataset%20ID&source=${callsource}`;

    const config = {
        headers: {Authorization: `Bearer ${accessToken}`},
        "Content-Type": "application/x-www-form-urlencoded",
    };

    return axios
        .get(apiURL, config)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            console.error(err);
        });
};

// * FETCH BLOGS

/**
 * Returns blogs from ShP list that are associated with a dataset
 * @param {string} id Dataset ID
 * @returns {Object[]}
 */
export const fetchAssociatedBlog = (id) => {
    const apiURL = `${ApiManagement}/public/admdata/ADMIN-BLOGS/json?filter=associated_dataset_id:${id}&source=${callsource}`;

    return axios
        .get(apiURL)
        .then((res) => {
            res.data = res.data.sort((a, b) => new Date(b.date) - new Date(a.date));

            return res;
        })
        .catch((err) => {
            console.error(err);
        });
};

/**
 * Returns markdown file of blog
 *
 * @param {string} id Blog name
 * @param {string} lang Blog language
 */
export const fetchBlogFile = (id, lang) => {
    const apiURL = `${BlogApiManagement}/${id}/${id}_${lang}.md`;

    return axios
        .get(apiURL)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            console.error(err);
        });
};

/**
 * Returns all blogs
 *
 * @returns
 */
export const fetchBlogList = () => {
    const apiURL = `${ApiManagement}/public/admdata/ADMIN-BLOGS/json?&source=${callsource}`;
    const apiHeaders = {
        "Content-Type": `application/json`,
        Accept: `application/json`,
        "Call-Source": callsource,
    };

    return axios
        .get(apiURL, apiHeaders)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            console.error(err);
        });
};

export const sendContactForm = (contactData) => {
    return axios.post(`${ApiManagement}/email`, contactData)
        .then((res) => {
            return res;
        }).catch((err) => {
            console.error(err);
        })
};

export const sendContributorEmail = (contactData) => {
    return axios.post(`${ApiManagement}/contrib`, contactData)
        .then((res) => {
            return res;
        }).catch((err) => {
            console.error(err);
        })
}

export const subscribeNewsletter = (signupform) => {
    return axios.post(`${ApiManagement}/newsletter`, signupform)
        .then((res) => {
            return res;
        }).catch((err) => {
            console.error(err);
        })
}
