import {Link} from 'react-router-dom';
import LinkButton from '../Global/LinkButton'
import {PublicAccess, RestrictedAccess} from './AccessIcon';
import {useTranslation} from "react-i18next";
import NoResults from "./NoResults";
import {AuthenticatedTemplate} from "@azure/msal-react";

const Analytics = ({items}) => {

    const sumPageView = (items) => {
        let sum = 0
        let json_values = eval(items)
        json_values.map((item, key) => {
            item.Requests.map((request, key) => {
                sum += request.Pageview
            })
        })
        return sum
    }

    const sumDownloads = (items) => {
        let sum = 0
        let json_values = eval(items)
        json_values.map((item, key) => {
            item.Requests.map((request, key) => {
                sum += request.Downloads
            })
        })
        return sum
    }

    return <div className="bg-white">
        <dl className="grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-2">
            <div className="flex flex-col bg-gray-400/5">
                <dt className="text-sm font-semibold leading-6 text-gray-600">Pageview</dt>
                <dd className="order-first text-2xl font-semibold tracking-tight text-gray-900">{sumPageView(items)}</dd>
            </div>
            {sumDownloads(items) ? (
                <div className="flex flex-col bg-gray-400/5">
                    <dt className="text-sm font-semibold leading-6 text-gray-600">Downloads</dt>
                    <dd className="order-first text-2xl font-semibold tracking-tight text-gray-900">{sumDownloads(items)}</dd>
                </div>) : (<></>)}
        </dl>
    </div>
}

export default function SearchResult({metadata}) {

    const {t} = useTranslation()

    const handleSubstring = (str) => {

        if (str.length <= 800) return str.split('\n').map(str => <p className="py-1">{str}</p>);

        str = str.substring(0, 800).replace(/\s+\S*$/, '') + "...";
        return str.split('\n').map(str => <p className="py-1">{str}</p>)
    }

    return (
        <div className="grid grid-cols-12 p-8 rounded-xl gray_shadow bg-white gap-y-4">
            <div className="col-span-12 flex flex-col md:flex-row justify-between md:items-center unorg-home-hero">
                <h4><Link
                    to={{pathname: `/dataset/${metadata["Dataset ID"]}`}}>{metadata.Name}</Link></h4>
                <span className="hidden md:block">
        <LinkButton to={`/dataset/${metadata["Dataset ID"].toString()}`}
                    className="btn_sm uppercase bg-un-blue text-white  hover:bg-sapphire-blue transition-colors">View</LinkButton>
        </span>
            </div>
            <div className="col-span-12 flex flex-row justify-between items-center">
                {metadata["Type"] === "Publicly Released" ? <PublicAccess/> : <RestrictedAccess/>}
            </div>

            <hr className="border-neutral-70 col-span-12"/>

            <div className="col-span-12 unorg-home-hero">
                <h4>{t('search.description')}</h4>
                <p className="p">{metadata["Description"] === null ? "Not Available" : handleSubstring(metadata["Description"])}</p>
            </div>
            {metadata["Last Update Date"] ?
                (<div className="col-span-12 unorg-home-hero">
                <h4>{t('search.last_updated')}</h4>
                <p>{metadata["Last Update Date"]}</p>
            </div>) : ""}

            <div className="col-span-12 unorg-home-hero">
                <h4>{t('search.update_frequency')}</h4>
                <p className="p">{metadata["Update Frequency"] === null ? "Restricted" : metadata["Update Frequency"]}</p>
            </div>

            <AuthenticatedTemplate>
                {metadata['Analytics Request'] ? <>
                    <div className="col-span-12 unorg-home-hero">
                        <h4>Analytics</h4>
                        <hr className="border-neutral-70 col-span-12 my-4"/>
                        <p>
                            {metadata['Analytics Request'] !== null ? <Analytics items={metadata['Analytics Request']}/> : ""}
                        </p>
                    </div>
                </> : ""}
            </AuthenticatedTemplate>
        </div>
    )
}