
import {Link} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import {useState} from "react";

export default function Footer() {

  const {t, i18n} = useTranslation()
  document.body.dir = i18n.dir()

  const UNLogo = () => {
    return require('../../../assets/unlogo_white_' + i18n.language + '.svg').default
  }

  return (
    <div style={{background: "#333333", borderTop: "#009edb solid 0.4rem"}}>
    <div className="container m-auto py-8 px-6">
      <div className="flex justify-between text-white" >

        <div className="flex space-x-8">
          <div className={i18n.language === 'ar' ? "ml-8 justify-end m-auto space-x-reverse" : ""}><a rel="noreferrer noopener" target="_blank"  href="https://www.un.org/"><img src={UNLogo()} alt="UN logo" style={{width: "12rem"}} /></a></div>
          <div className="flex flex-col md:space-y-6">
            <p><Link className="font-bold footer_hover text-sm md:text-base" to="/search">{t('footer.find_dataset')}</Link></p>
            <p><Link className="font-bold footer_hover text-sm md:text-base" to="/themes">{t('footer.browse_by_theme')}</Link></p>
            <p><Link className="font-bold footer_hover text-sm md:text-base" to="/resources">{t('footer.learn_more')}</Link></p>
          </div>
          <div className="flex flex-col space-y-6">
            <p><Link className="font-bold footer_hover text-sm md:text-base" to="/stories">{t('footer.data_stories')}</Link></p>
            <p><Link className="font-bold footer_hover text-sm md:text-base" to="/howto">{t('footer.how_to')}</Link></p>
            <p><Link className="font-bold footer_hover text-sm md:text-base" to="/about">{t('footer.about')}</Link></p>
          </div>
          <div className="flex flex-col space-y-4">
            <p><Link className="font-bold footer_hover text-sm md:text-base" to="/contact">{t('about.contact_us')}</Link></p>
          </div>
        </div>
      </div>
    </div>
    <div className="px-8 py-4 border-t border-white">
      <ul className="flex text-white space-x-8 text-sm md:text-base">
        <li className="ml-8"><a href={"https://www.un.org/" + i18n.language +"/about-us/copyright/"}>{t('footer.copyright')}</a></li>
        <li><a href={"https://www.un.org/" + i18n.language +"/about-us/fraud-alert"}>{t('footer.fraud_alert')}</a></li>
        <li><a href={"https://www.un.org/" + i18n.language +"/about-us/privacy-notice"}>{t('footer.privacy')}</a></li>
        <li><a href={"https://www.un.org/" + i18n.language +"/about-us/terms-of-use"}>{t('footer.terms')}</a></li>
      </ul>
    </div>
    </div>
  )
}