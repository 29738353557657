import {useTranslation} from "react-i18next";

export default function LanguageBanner() {

  const {t, i18n} = useTranslation();


  return (
    <div className="py-10 flex flex-col lg:flex-row">
      <ul className={i18n.language === 'ar' ? "lg:flex lg:flex-row space-x-8 justify-end container m-auto space-x-reverse" : "lg:flex lg:flex-row lg:space-x-8 sm:space-x-0  justify-end container m-auto"}>
        <li className={ i18n.language === 'ar' ? "font-bold sm:mb-2" : "sm:mb-2"}>
            <a href="#" onClick={() => i18n.changeLanguage('ar')}>عربي</a>
        </li>
        <li className={ i18n.language === 'ch' ? "font-bold sm:mb-2" : "sm:mb-2"}>
            <a href="#" onClick={() => i18n.changeLanguage('ch')}>中文</a>
        </li>
        <li className={ i18n.language === 'en' ? "font-bold sm:mb-2" : "sm:mb-2"}>
          <a href="#" onClick={() => i18n.changeLanguage('en')}>English</a>
        </li>
        <li className={ i18n.language === 'fr' ? "font-bold sm:mb-2" : "sm:mb-2"}>
            <a href="#" onClick={() => i18n.changeLanguage('fr')}>Français</a>
        </li>
        <li className={ i18n.language === 'ru' ? "font-bold sm:mb-2" : "sm:mb-2"}>
            <a href="#" onClick={() => i18n.changeLanguage('ru')}>Русский</a>
        </li>
        <li className={ i18n.language === 'es' ? "font-bold" : null}>
            <a href="#" onClick={() => i18n.changeLanguage('es')}>Español</a>
        </li>
      </ul>
    </div>
  )
}