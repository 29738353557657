import { useState, useEffect } from "react";

import { fetchBlogList } from "../shared/utils/fetchapi";

import YellowSpiral from "../assets/img/smallyellowwave.png";
import Story from "../components/DataStories";

import StoryFourImg from "../assets/img/psdh_blog.jpeg";
import {useTranslation} from "react-i18next";

const CACHE = {};
export default function DataStories() {
  const [blogList, setBlogList] = useState([]);
  const {t, i18n} = useTranslation()
  document.body.dir = i18n.dir()

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let getBlogList = async () => {
    if (CACHE.blog !== undefined) {
      setBlogList(CACHE.blog);
      return;
    }
    const response = await fetchBlogList();
    let distinct = [];
    let unique = [];

    for (let i = 0; i < response.data.length; i++) {
      if (!unique.includes(response.data[i]["link"])) {
        unique.push(response.data[i].link);
        distinct.push(response.data[i]);
      }
    }

    distinct = distinct
      .sort((a, b) => new Date(a.date) - new Date(b.date))
      .slice(1)
      .reverse(); // sort by date and remove Intro post
    let blogs = distinct;
    CACHE.blog = blogs;
    setBlogList(blogs);
  };

  useEffect(() => {
    getBlogList();
  }, []);

  return (
    <>
      <div
        className="container mx-auto header grid grid-cols-12 gap-x-6 px-6 overflow-hidden mt-auto"
        style={{ height: "500px" }}
      >
        <div className="col-span-12 md:col-span-6 col-start-2 flex flex-col space-y-8 unorg-home-hero mt-10">
          <h1>{t('stories.title')}</h1>
          <p className="text-black">{t('stories.slogan')}</p>
        </div>
        <div className="hidden col-span-12 md:col-span-4 md:col-start-9 relative md:flex flex-col justify-center px-6 mb-20">
          <div className="relative" style={{ height: "100%" }}>
            <div className="bottom-0 absolute">
              <Story
                name="Introducing the Peace and Security Data Hub"
                url="/blog/introducing/introducing"
                image={StoryFourImg}
                language={i18n.language}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container mx-auto px-6 grid grid-cols-12 gap-x-6 mb-20">
        <div className="col-span-12 col-start-1">
          <div className="grid grid-cols-9 gap-x-12 gap-y-12">
            {blogList &&
              blogList.map((i, k) => {
                return (
                  <div className="col-span-6 md:col-span-3">
                    <Story
                      image={`${
                        process.env.REACT_APP_API_MANAGEMENT ||
                        "https://api.psdata.un.org"
                      }/public/blogs/${i.image}`}
                      name={`${i.blog_name}`}
                      url={`blog/${i.link}`}
                      language={i18n.language}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>

      <div className="container mx-auto px-6 mb-20 grid grid-cols-12">
        <div className="col-span-10 col-start-2">
          <p className="text-black">
            {t('stories.tell_stories')}{" "}
            <a href="/contact" className="text-sapphire-blue button_secondary">
              {t('stories.tell_us')}
            </a>
          </p>
        </div>
      </div>
    </>
  );
}
