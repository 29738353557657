import LinkButton from '../Global/LinkButton';
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";

export const BlogList = ({blogList}) => {

    const {t} = useTranslation();

    return (
        <div className="mx-auto mt-20 relative overflow-hidden bg-white">
            <div className="h-full w-32 absolute left-0" style={{zIndex: -1}}></div>
            <div className="grid grid-cols-12 mx-6 gap-x-6 container mx-auto px-6">

                <div className="col-span-8 unorg-home-hero">
                    <div className="grid grid-cols-8 gap-x-6 py-10 md:py-20">
                        <h3 className="col-span-12 md:col-span-5">{t('home.how_our_peers')}</h3>
                    </div>
                </div>


                <div className="col-span-12 md:col-span-4 md:col-start-10 md:pt-20 pb-20">
                    <LinkButton to="/stories/"
                                className="btn_md  uppercase bg-un-blue text-white  hover:bg-sapphire-blue transition-colors">{t('home.read_the_stories')}</LinkButton>
                </div>

                {/******************
                 ** UPDATE STORIES **
                 *******************/}

                <div
                    className="col-span-12 flex flex-col md:flex-row md:space-x-4 justify-between space-y-8 md:space-y-0 m-auto md:m-0">
                    {blogList &&
                        blogList.map((i, k) => {
                            return (
                                <DataStory
                                    img={`${process.env.REACT_APP_API_MANAGEMENT || 'https://api.psdata.un.org'}/public/blogs/${i.image}`}
                                    title={`${i.blog_name}`} url={`blog/${i.link}`} key={k}/>
                            )
                        })
                    }

                    {/* TEMPLATE DATA STORY */}
                    {/* <DataStory img={StoryFiveImg} title="“Behind the Numbers”: Data and Stories on Women in Peacemaking " url="stories/story5"/> */}
                </div>

            </div>
        </div>
    )
}

const DataStory = (props, {img, title}) => {
    const {t} = useTranslation();
    const [foundKey, setFoundKey] = useState(null);

    const translations = {
        "blogTitles": {
            "introducing": "Introducing the Peace and Security Data Hub",
            "quick_impact_projects": "What Are Quick Impact Projects",
            "measuring_peacebuilding": "Measuring Peacebuilding Funding",
            "fatalities": "How We Use The UN Peacekeeper Fatalities Dataset",
            "behind_numbers": "Behind the Numbers: Data and stories on women in peacemaking",
            "oda_peacebuilding": "ODA and Peacebuilding",
            "language_of_peace": "Language of Peace Database",
            "howto_data_story": "How to Tell a Data Story – Transform Data to Actionable Insights",
            "scads_data": "SCAD’s data, a key to better understanding the work of the Security Council",
            "wps_agenda": "Quantifying process in the implementation of the Women, Peace and Security agenda in peace operations through text analysis",
            "pema_story": "Peacekeeping Mandates (PEMA) Dataset",
            "peace_datahub_story": "Predictive modeling with peacekeeping demographic data",
            "hdx": "UN Peace and Security Data on HDX"
        }
    };

    // Function to find the key based on a given value
    function findKeyByValue(obj, value) {
        return Object.keys(obj).find(key => obj[key] === value);
    }

    useEffect(() => {
        const key = findKeyByValue(translations.blogTitles, props.title);
        setFoundKey('blogs.' + key);
    }, [translations.blogTitles, props.title]);

    return (
        <a href={props.url} className="flex flex-col items-center space-y-4" style={{width: 280}}>
            <div className="rounded-2xl flex flex-col justify-center" style={{minHight: 200}}>
                <img src={props.img} className="rounded-2xl object-cover w-full h-full" alt=""/>
            </div>
            <div className="flex space-x-4">
                <span className="uppercase text-sm button_secondary">{foundKey ? t(foundKey) : ''}</span>
                <span className="mt-1">
          <svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path
              d="M1 0.5L6 6.125L1 11.75" stroke="#000000"></path></svg>
        </span>
            </div>

        </a>
    )
}
