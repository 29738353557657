import { useEffect } from 'react';
import { Link } from 'react-router-dom'
import StockImg from '../assets/img/linkedin-sales-navigator.png';
import YellowSpiral from '../assets/img/GEO_Vector12.png';
import {useTranslation} from "react-i18next";


export default function About () {

  useEffect(()=>{
    window.scrollTo(0, 0);
  }, [])

  const {t, i18n} = useTranslation()
  document.body.dir = i18n.dir()

  return (
    <>
    <div className="container mx-auto header grid grid-cols-12 gap-x-6 px-6 overflow-hidden">
      <div className="col-span-12 md:col-span-6 col-start-2 flex flex-col space-y-8 unorg-home-hero mt-10">
        <h1>{t('about.title')}</h1>
      </div>
    </div>

    <div className="container mx-auto px-6 grid grid-cols-12 gap-x-6 mb-20 mt-10">
      <div className="col-span-12 space-y-8">
        <p className="font-light text-2xl">
          {t('about.the')} <a href="/blog/introducing/introducing" className="text-sapphire-blue">Peace and Security Data Hub</a> {t('about.intro')}
        </p>

        <p className="font-light text-xl md:text-2xl">
        {t('about.direct')} <a className="text-sapphire-blue" href="https://www.un.org/en/content/datastrategy/index.shtml" target="_blank" rel="noreferrer noopen">{t('about.secretary_general')}</a>, {t('about.which_calls')}
        </p>
        <p className="font-light text-xl md:text-2xl">
          {t('about.we_anticipate')}
        </p>
        <p className="font-light text-xl md:text-2xl">
          {t('about.current_version')}
        </p>

        <hr className="border-neutral-30" />

        <h4 className="font-bold">{t('about.disclaimer')}</h4>
        <p className="font-light text-xl">{t('about.data_collected')} </p>
        <p className="font-light text-xl">{t('about.certain_names')} </p>

        <hr className="border-neutral-30" />

        <h4 className="font-bold">{t('about.reach_out')}</h4>
        <p className="font-light text-xl">{t('about.want_to_learn')}</p>
        <div className="uppercase flex space-x-4 items-center  pt-8">
          <Link to='/contact' className="text-sm font-bold button_secondary"><span>{t('about.contact_us')}</span></Link>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="12" fill="#80A8D0"/><path d="M9.5 14.9401V9.05991C9.5 8.62843 9.91802 8.35959 10.2509 8.577L14.7523 11.5171C15.0826 11.7328 15.0826 12.2672 14.7523 12.4829L10.2509 15.423C9.91802 15.6404 9.5 15.3716 9.5 14.9401Z" fill="white"/></svg>
        </div>
      </div>
    </div>


    <div className="grid grid-cols-12 gap-x-6 px-6">
      <div className="col-span-10 col-start-2">
      </div>
    </div>

    </>

  )
}