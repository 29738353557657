import CloseButton from "../../assets/x_close.svg";
import {useTranslation} from "react-i18next";

const SearchBar = ({
  innerRef,
  selectedFilters,
  setSelectedFilters
}) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    const query = innerRef.current.value;

    let selectedFilters_ = typeof selectedFilters === "string" ? JSON.parse(selectedFilters) : selectedFilters
    selectedFilters_["search"] = [query]

    /* SERIALIZE OBJ */
    setSelectedFilters(JSON.stringify(selectedFilters_))
  };

  const handleClearSearch = () => {
    // CLEAR SEARCHBAR
    innerRef.current.value = ''
    
    /* DESERIALIZE OBJ */
    let selectedFilters_ = typeof selectedFilters === "string" ? JSON.parse(selectedFilters) : selectedFilters
    selectedFilters_["search"] = []

    /*Serialize filters */
    setSelectedFilters(JSON.stringify(selectedFilters_))
  };

  const {t} = useTranslation()

  return (
    <>
      <div className="search-bar flex flex-row justify-between items-center">
        <div className="search-group w-full relative">
          <form onSubmit={handleSubmit}>
            <input
              ref={innerRef}
              className="h-14 rounded-full w-full px-8 gray_shadow"
              id="searchbar"
              type="text"
              placeholder={t('generic.search')}
            />
          </form>
          <div
            id="searchclear"
            className="absolute right-5 m-auto top-0 bottom-0 h-6 text-black cursor-pointer flex flex-col justify-center"
            onClick={handleClearSearch}
          >
            <img src={CloseButton} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export { SearchBar };
