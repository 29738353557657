import {Link} from'react-router-dom';
import GettingStartedImg from '../../assets/img/stock/gettingstarted_1.jpg';
import GreenWave from '../../assets/img/greenwave.png';
import {useTranslation} from "react-i18next";

export const GettingStarted = () => {

  const {t} = useTranslation();

  return (
    <div className="container mx-auto relative">
      <div className="grid grid-cols-12 h-full py-20 mx-6 gap-x-6">
        <div className="col-span-4 col-start-2 h-full hidden md:flex flex-col justify-center">
          <div style={{height: 520}} className="relative">
            <div className="absolute" style={{bottom:80, left: -72, borderRadius:30, width:143, height: 143, background: "linear-gradient(0deg, rgba(251,225,28,0.32394964821866246) 0%, rgba(25,144,254,0.8) 100%)" }}></div>
            <img className="absolute" style={{zIndex: "-1", top: "10px", right: "-130px"}} src={GreenWave} alt=""/>
            <div className="flex items-center justify-center overflow-hidden h-full" style={{borderRadius:45}}>
              <img className="" style={{width:"100%", height:"100%", objectFit: "cover", objectPosition:"right"}} src={GettingStartedImg} alt="" />
            </div>
          </div>
        </div>
        <div className="col-span-12 md:col-span-5 col-start-1 md:col-start-7 h-full flex flex-col justify-center space-y-8">
          <div className="flex flex-col space-y-10 unorg-home-hero">
            <h2>{t('home.getting_started')} <span style={{"whiteSpace": "nowrap"}}>{t('home.open_data')}</span></h2>
            <p className="text-md md:text-2xl text-roboto font-light">{t('home.are_looking')}</p>
            <div className="uppercase flex space-x-4">
              <Link to="/howto" className="text-sm font-bold button_secondary">{t('home.how_use_database')}</Link>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="12" fill="#80A8D0"/><path d="M9.5 14.9401V9.05991C9.5 8.62843 9.91802 8.35959 10.2509 8.577L14.7523 11.5171C15.0826 11.7328 15.0826 12.2672 14.7523 12.4829L10.2509 15.423C9.91802 15.6404 9.5 15.3716 9.5 14.9401Z" fill="white"/></svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}