/** @jsxRuntime classic */

import "react-app-polyfill/ie11";
// import 'react-app-polyfill/stable';
import "core-js/stable";
// import 'regenerator-runtime/runtime';

import React from "react";

import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import "./styles/tailwind.css";

import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";

import { encrypt, decrypt } from "./shared/utils/encrypt";

import ReactGA from "react-ga";
import { createBrowserHistory } from "history";
import App from "./App";

import './i18n'


// TESTING TRACKING CODE
const trackingId = "UA-191755380-1"//;https://github.com/IMU-DPPA-DPO/psdatahub-fe/pull/8/conflict?name=src%252Findex.js&ancestor_oid=4711ff62517a453199913541127ef248ebcdeaf0&base_oid=02c0930f0defb99e5e8ad779d8f939ca2d776bec&head_oid=d607ce761b0ad1cd77049e080f99693795783e8f

// PRODUCTION TRACKING IDzs
// const trackingId = "UA-191755380-2"

ReactGA.initialize(trackingId);
const history = createBrowserHistory();
ReactGA.pageview(window.location.pathname);

history.listen((location) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

//  AUTHENTICATION
const msalInstance = new PublicClientApplication(msalConfig);

const accounts = msalInstance.getAllAccounts();

if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;

    const username = encrypt(account.username);


    ReactGA.set({ userId: username });
    ReactGA.set({ UserId: username });
    ReactGA.set({ dimension1: username });

    msalInstance.setActiveAccount(account);
  }
});

ReactDOM.render(
  <React.StrictMode>
    {/* <LanguageBanner /> */}
    <React.Suspense fallback="loading">
      <App msalInstance={msalInstance} />
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
