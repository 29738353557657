import { useState, useEffect } from 'react';

import StockImg from '../assets/img/stock/resources_stock.jpg';

import { fetchExternalResources } from '../shared/utils/fetchapi.js';

import './styles/resources.css'
import {useTranslation} from "react-i18next";

const CACHE = {}

export default function AdditionalResources () {

  const [list, setList]=useState([]);
  const [listLoaded, setListLoaded] = useState(false)
  const {t, i18n} = useTranslation()
  document.body.dir = i18n.dir()

  useEffect(()=>{
    if(list.length > 0){
      setListLoaded(true)
    }
  }, [list])


  let getResources = async() => {
    if (CACHE.logos!==undefined){
      setList(CACHE.logos)
      return
    }
    const response = await fetchExternalResources();
    CACHE.logos = response.data;
    setList(response.data);
  }


  useEffect(()=>{
    getResources();

  }, []);


  useEffect(()=>{
    window.scrollTo(0, 0);
  }, []);

  return (
    <>

    <div className="h-screen w-full  absolute  bg-cover  " style={{height: 500, backgroundSize:"50%", backgroundRepeat:"no-repeat", backgroundPosition: "right top"}}></div>
    <div className="container relative mx-auto header grid grid-cols-12 gap-x-6 px-6 overflow-hidden" style={{ "height":"500px" }} >
      <div className="col-span-12 md:col-span-8 col-start-2 flex flex-col space-y-8 unorg-home-hero mt-10">
        <h1 className="font-bold">{t('additional.title')}</h1>
      </div>
      <div className="hidden md:block col-span-4 col-start-8 relative">
        <div className="absolute right-0 -bottom-10 h-5/6 overflow-hidden left-0">
          <div className="h-full">

          </div>
        </div>
      </div>
    </div>
    {
      listLoaded ?
    <div className="container px-6 mx-auto grid grid-cols-12 py-16 gap-x-6">
      <div className="col-span-8 col-start-3">
        <h4 className="font-light">{t('additional.slogan')}</h4>
      </div>
    </div>
    : <div className="mt-20"></div>
    }
    <div className="container mx-auto px-6 grid grid-cols-12 gap-x-6 mb-20">
      <div className="col-span-9 col-start-3">
        <div className="grid grid-cols-9 gap-x-6 gap-y-12">
          {
            listLoaded ?
            list.map((i,k)=>{
              return (
                <>
                {

                <div className="col-span-9 md:col-span-3">
                  <div className="flex flex-col items-center space-y-4">
                    <div className="rounded-2xl w-full h-36 flex flex-col items-center justify-center">
                      <img alt={`${i.Name} Logo`} src={`data:image/svg+xml;base64,${i.Icon}`} className="max-h-full p-8"/>
                    </div>
                    <div className="flex">
                      <a href={i["Link to external dataset"]} target="_blank" rel="noreferrer" className="flex  text-center space-x-6 button_secondary">
                        <span className="uppercase text-sm">Visit {i["Name"]}</span>
                        <span className="mt-1"><svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 0.5L6 6.125L1 11.75" stroke="#000000"></path></svg></span>
                      </a>
                    </div>
                  </div>
                </div>
                }
                </>
              )
            })
                : <div className="w-full h-full flex flex-col items-center col-span-12 justify-center"><div className="lds-ripple"><div></div><div></div></div></div>
          }
        </div>
      </div>
    </div>
    </>

  )
}