import { InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";

import { useState, useEffect } from "react";

import {
  fetchMetadata,
  fetchMetadataInternal,
  fetchBlogList,
} from "../shared/utils/fetchapi.js";

import { Hero } from "../components/Home/Hero";
import { BlogList } from "../components/Home/BlogList";
import { GettingStarted } from "../components/Home/GettingStarted";
import { CantFind } from "../components/Home/CantFind";

const CACHE = {};
export default function Home() {
  const { instance, inProgress } = useMsal();

  const [mainList, setMainList] = useState([]);
  const [blogList, setBlogList] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let getBlogList = async () => {
    if (CACHE.blog !== undefined) {
      setBlogList(CACHE.blog);
      return;
    }
    const response = await fetchBlogList();
    let distinct = [];
    let unique = [];

    for (let i = 0; i < response.data.length; i++) {
      if (!unique.includes(response.data[i]["link"])) {
        unique.push(response.data[i].link);
        distinct.push(response.data[i]);
      }
    }

    let sorted = distinct.sort((a, b) => new Date(a.date) - new Date(b.date));

    let blogs = sorted.slice(-4).reverse();
    CACHE.blog = blogs;
    setBlogList(blogs);
  };

  useEffect(() => {
    getBlogList();
  }, []);

  useEffect(async () => {
    // This Deletes the Cache
    delete CACHE.all;
    const accessTokenRequest = {
      scopes: [
        process.env.REACT_APP_MSAL_API_SCOPE ||
          "https://api-psdatadev.un.org/api.call",
        "user.read",
      ],
    };

    // Tries to get token to call internal list
    // Otherwise call public API

    if (inProgress === InteractionStatus.None) {
      if (instance.activeLocalAccountId) {
        const accessTokenResponse = await instance
          .acquireTokenSilent(accessTokenRequest)
          .then((accessTokenResponse) => {
            let accessToken = accessTokenResponse.accessToken;
            // Call your API with token
            delete CACHE.all;
            getMasterListInternal(accessToken);
          })
          .catch((error) => {
            if (error) {
              // fallback to interaction when silent call fails
              return instance.acquireTokenRedirect(accessTokenRequest);
            }
            // getMasterListPublic()
          });
      } else {
        // If there is no active msal instance call public
        getMasterListPublic();
      }

      // console.log(accessTokenResponse)
      //       let accessToken = accessTokenResponse.accessToken;

      //   instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
      // console.log("getting access token")
      //       // Acquire token silent success
      //       let accessToken = accessTokenResponse.accessToken;
      //       // Call your API with token
      //       delete CACHE.all
      //       getMasterListInternal(accessToken)
      //   })
      //   .catch(() => {
      //     console.log("didn't get")
      //     // getMasterListPublic()
      //   })
    }
  }, [instance, inProgress]);


  let getMasterListInternal = async (accessToken) => {
    if (CACHE.all !== undefined) {
      setMainList(CACHE.all);
      return;
    }
    const response = await fetchMetadataInternal("all", accessToken);
    CACHE.all = response.data;
    setMainList(response.data);
  };

  let getMasterListPublic = async () => {
    if (CACHE.all !== undefined) {
      setMainList(CACHE.all);
      return;
    }
    const response = await fetchMetadata("all");
    CACHE.all = response.data;
    setMainList(response.data);
  };

  return (
    <>
      <Hero mainList={mainList} />
      <BlogList blogList={blogList} />
      <GettingStarted />
      <CantFind />
    </>
  );
}
