import React, {useEffect, useRef, useState} from "react";
import Arrow from '../../../assets/ScrollToTop.svg';

import './styles.css';
import {useTranslation} from "react-i18next";

export default function ScrollToTop() {
  const [isVisible, setIsVisible] = useState(false);

  const {t} = useTranslation()

  // Show button when page is scorlled upto given distance
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Set the top cordinate to 0
  // make scrolling smooth
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <div className="scroll-to-top col-start-12">
      {isVisible &&

        <div onClick={scrollToTop} className="flex flex-col items-center">
          <img src={Arrow} alt='Go to top'/>
          <p>{t('generic.back_top')}</p>
        </div>
        }
    </div>
  );
}
