import React, { useState, useEffect, useRef } from "react";
import RightArrow from "../../assets/right_arrow_purple.svg";
import CloseButton from "../../assets/x_close.svg";

import { fetchCountryList } from "../../shared/utils/fetchapi";

const CACHE = {};

/**
 * Shows the country filter menu
 *
 * @param {string} setCQuery Current country list
 * @param {Boolean} setShowCountryFilter Country filter state
 */
export default function CountryFilter({
  setShowCountryFilter,
  setSelectedFilters,
  selectedFilters,
}) {
  const [countryTab, setCountryTab] = useState("All");
  const [countries, setCountries] = useState([]);
  const [allCountries, setAllCountries] = useState([]);
  const [allContinents, setAllContinents] = useState([]);

  const [render, triggerRender] = useState(false);

  // Alphabetically sorts Missions
  const GetSortOrder = (prop) => {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  };

  // Imports countries list file and stores them
  const getCountries = async () => {
    if (CACHE["countries"] !== undefined) {
      setCountries(CACHE["countries"]);
      setAllCountries(CACHE["allcountries"]);
      setAllContinents(CACHE["continents"]);
      return;
    }

    // Calls country list API and sorts into Continents
    const countryList = await fetchCountryList();

    try {
      let sortedCountryList = countryList.data.sort(
        GetSortOrder("country_or_area")
      );
      CACHE["countries"] = sortedCountryList;
      CACHE["allcountries"] = sortedCountryList;
      setCountries(sortedCountryList);
      setAllCountries(sortedCountryList);
      const distinctContinents = [
        ...new Set(sortedCountryList.map((x) => x["region_name"])),
      ];

      let continents = {};
      for (let continent in distinctContinents) {
        if (distinctContinents[continent] === null) {
          distinctContinents[continent] = "Antarctica";
        }
        continents[distinctContinents[continent]] = [];
      }
      CACHE["continents"] = continents;
      setAllContinents(continents);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getCountries();
  }, []);

  // Display countries from a continent
  const handleTab = (e) => {
    let c;

    switch (e) {
      case "All":
        setCountries(allCountries);
        break;
      case "Africa":
        c = allCountries.filter((x) => x["region_name"] === "Africa");
        setCountries(c);
        break;
      case "Americas":
        c = allCountries.filter((x) => x["region_name"] === "Americas");
        setCountries(c);
        break;
      case "Antarctica":
        c = allCountries.filter((x) => x["region_name"] === null);
        setCountries(c);
        break;
      case "Asia":
        c = allCountries.filter((x) => x["region_name"] === "Asia");
        setCountries(c);
        break;
      case "Europe":
        c = allCountries.filter((x) => x["region_name"] === "Europe");
        setCountries(c);
        break;
      case "Oceania":
        c = allCountries.filter((x) => x["region_name"] === "Oceania");
        setCountries(c);
        break;
      default:
        setCountries(allCountries);
        break;
    }
  };

  // Adds selected country to selected continent list
  const handleSelect = (e) => {
    let continentListToUpdate = allContinents;
    const c = e;
    if (
      continentListToUpdate[e.continent] === undefined ||
      continentListToUpdate[e.continent] === "Antarctica"
    ) {
      c.continent = "Antarctica";
    }
    if (continentListToUpdate[c.continent].includes(c.country)) {
      const index = continentListToUpdate[c.continent].indexOf(c.country);
      continentListToUpdate[c.continent].splice(index, 1);
    } else {
      continentListToUpdate[c.continent].push(c.country);
    }
    setAllContinents(continentListToUpdate);
    triggerRender(true);
  };

  useEffect(() => {
    triggerRender(false);
    return () => {};
  }, [render]);

  useEffect(() => {
    let checkboxes = document.getElementsByClassName("countrycheckbox");
    Object.keys(allContinents).map((i) => {
      allContinents[i].map((j) => {
        let input_ = document.getElementsByName(j)[0];
        input_.checked = true;
      });
    });
  }, [allContinents]);

  // Logic for Select All button
  const addAllBtn = (e) => {
    let checkboxes = document.getElementsByClassName("countrycheckbox");
    for (let i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].checked === false) {
        checkboxes[i].click();
      }
      checkboxes[i].checked = true;
    }
  };

  const clearAllBtn = (e) => {
    let checkboxes = document.getElementsByClassName("countrycheckbox");
    for (let i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].checked === true) {
        checkboxes[i].click();
      }
      checkboxes[i].checked = false;
    }
  };

  // Closing menu updates search page
  const handleClose = () => {
    // let passToParent = [];
    // setCQuery(allContinents);
    setShowCountryFilter(false);

    let selectedFiltersOBJ = typeof selectedFilters === "string" ? JSON.parse(selectedFilters) : selectedFilters
    let selectedCountries = allContinents;

    selectedFiltersOBJ["countries"] = allContinents

    setSelectedFilters(JSON.stringify(selectedFiltersOBJ))
    

  };

  return (
    <div className="col-span-9 col-start-4 bg-white p-8 space-y-8 sticky">
      <div className="grid grid-cols-9 bg-white">
        <div className="col-span-2">
          <div className="mt-8">
            <ul className="text-base font-light space-y-4">
              <li>
                <span
                  role="button"
                  className={
                    countryTab === "All"
                      ? "font-normal text-persian-indigo"
                      : "font-light"
                  }
                  onClick={(e) => {
                    handleTab("All");
                  }}
                >
                  <div className="flex justify-between items-center mr-8">
                    All
                  </div>
                </span>
                <hr className="my-2 mr-8" />
              </li>
              {Object.keys(allContinents).map((i, k) => {
                return (
                  <li key={k} className="">
                    {/* <span className="text-base" role="button" onClick={(e)=>setMissionTab(i)}>{i}</span> */}
                    <span
                      role="button"
                      className={
                        countryTab === i
                          ? "font-normal text-persian-indigo"
                          : "font-light"
                      }
                      onClick={() => {
                        handleTab(i);
                      }}
                    >
                      <div className="flex justify-between items-center mr-8">
                        {i}
                        <span>
                          {allContinents[i] !== undefined &&
                          allContinents[i].length > 0 ? (
                            `(${allContinents[i].length})`
                          ) : (
                            <></>
                          )}
                        </span>
                        <span className={countryTab === i ? "block" : "hidden"}>
                          <img src={RightArrow} alt="" />
                        </span>
                      </div>
                    </span>
                    <hr className="my-2 mr-8" />
                    {/* <hr className="mr-8" /> */}
                  </li>
                );
              })}
            </ul>
          </div>
          <div className=""></div>
        </div>
        <div className="col-span-7">
          <div className="flex space-x-4 mb-8 justify-between">
            <div className="space-x-4">
              <button
                className="text-xs text-roboto font-bold"
                onClick={addAllBtn}
              >
                Select All
              </button>
              <button
                className="text-xs text-roboto font-bold text-neutral-76"
                onClick={clearAllBtn}
              >
                Remove All
              </button>
            </div>
            <button
              onClick={handleClose}
              className="flex space-x-4 items-center"
            >
              <span className="text-sm">Close</span>
              <img src={CloseButton} alt="" />
            </button>
          </div>

          <ListOfCountries
            countries={countries}
            countryTab={countryTab}
            setCountryTab={setCountryTab}
            handleSelect={handleSelect}
          />
        </div>
      </div>
    </div>
  );
}

const ListOfCountries = (props, { countries, countryTab, setCountryTab }) => {
  let alphabetized = {};
  props.countries.map((c) => {
    if (alphabetized.hasOwnProperty(c.country_or_area[0])) {
      let key = c.country_or_area[0];
      alphabetized[key].push({
        country: c.country_or_area,
        continent: c.region_name,
      });
    } else {
      alphabetized[c.country_or_area[0]] = [
        { country: c.country_or_area, continent: c.region_name },
      ];
    }
  });
  return Object.keys(alphabetized).map((i, k) => {
    return (
      <>
        <p className="text-sm text-un-blue">{i}</p>
        <hr className="border-un-blue my-4" />
        <ul className="grid grid-cols-3 pb-4">
          {alphabetized[i].map((c, key) => {
            return (
              <li className="my-2" key={key}>
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    onChange={() => props.handleSelect(c)}
                    className="countrycheckbox rounded-full text-persian-indigo focus:ring-persian-indigo h-4 w-4"
                    name={c.country}
                  />
                  <span className="ml-2 text-xs">{c.country}</span>
                </label>
              </li>
            );
          })}
        </ul>
      </>
    );
  });
};
