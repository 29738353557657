import { useEffect, useState } from "react";
import { fetchThemes } from "../utils/fetchapi";

const useFetchThemes = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const getThemes= async () => {
      const response = await fetchThemes();
      setData(response["data"]);
    };

    getThemes();
  }, []);

  return data;
};

export { useFetchThemes };
